import { useNavigation } from '@react-navigation/core';
import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, Text, ScrollView } from 'react-native'
import { connect, useDispatch, useSelector } from 'react-redux';
import { backgroundColor, colorTextDark } from '../../constants/color';
import { CheckLocationIsServed } from '../../helpers/helpers';
import { SetOrderLocation } from '../../reducers/order';
import ActionBtn from '../shared/ActionBtn';
import LocationCard from '../shared/LocationCard';
const Locations = ({ route, SetOrderLocation, user }) => {
    let { select } = route.params || {}
    const navigation = useNavigation()
    const [userLocations, setUserLocations] = useState(user?.locations)
    const { strings } = useSelector((state) => state.locale);
    const { allLocations } = useSelector((state) => state.settings);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            let userLocations = user?.locations?.map(rec => {
                let { place_id, cluster } = dispatch(CheckLocationIsServed(rec?.geometry?.location));
                return ({
                    ...rec,
                    type: 'delivery',
                    active: !!place_id,
                    params: allLocations?.[cluster] || {}
                })
            })
            setUserLocations(userLocations)
        })()

    }, [user?.locations, allLocations])

    return (
        <View style={{ paddingTop: 32, flex: 1, backgroundColor: backgroundColor }}>
            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ paddingBottom: 100 }}>
                {
                    userLocations && userLocations.length ? userLocations?.map(rec =>
                        <View style={{ opacity: !rec?.active ? 0.5 : 1 }} key={rec.id}>
                            <TouchableOpacity
                                onPress={() => {
                                    if (select && rec?.active) {
                                        SetOrderLocation({ ...rec, type: 'delivery' })
                                        navigation.goBack()
                                    }
                                }}
                                style={{ paddingBottom: 8, paddingHorizontal: 16 }}>
                                <LocationCard data={{ ...rec, name: user?.name, email: user?.email }} user={user} edit disabled={select} profile={true} userLocations={userLocations} />
                            </TouchableOpacity>
                        </View>
                    ) : <View
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '85%'
                        }}>
                        <Text style={{ fontSize: 18, color: colorTextDark }}>{strings.noLocations}</Text>
                    </View>
                }
            </ScrollView>
            <ActionBtn desktop={true} title={strings.addNewAddress} onPress={() => navigation.navigate('Choose your location')} />
        </View>
    );
};
const mapStateToProps = ({ user }) => {
    return { user }
}
export default connect(mapStateToProps, { SetOrderLocation })(Locations);