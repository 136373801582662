import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, ScrollView, TextInput, Platform, KeyboardAvoidingView } from 'react-native';
import { backgroundColor, colorTextGrey, greenColor, mainColor, redColor } from '../../constants/color';
import LocationCard from '../shared/LocationCard';
import Image from '../shared/customImg'
import { FilledDot, Watch, Trash, collapseSVG, collapseSVGUP, Dot, FreeDeliverySVG } from '../../constants/imgs';
import ActionBtn from '../shared/ActionBtn';
import SelectTime from './SelectTime';
import CartCard from '../shared/CartCard';
import { useNavigation } from '@react-navigation/core';
import { Text } from '../shared/custom'
import { connect, useDispatch, useSelector } from 'react-redux';
import { calcTotalCost, makeid, translateSelectedSlot, applyPromo, CheckStoreClosed } from '../../helpers/helpers';
import { setOrderDiscount, FlushOrder, SetOrderDeliveryDate } from '../../reducers/order';
import { GetOffers } from '../../reducers/offers';
import { firestore } from '../../constants/firebase/config'
import moment from 'moment';
import CustomImage from '../shared/customImg'
import Alert from '../shared/Alert';
import { useHeaderHeight } from '@react-navigation/elements';
import OrderOption from '../home/OrderOption';
import { SetAlertMessage } from '../../reducers/settings';
import { formatTime } from '../shared/ClosedBanner';
import { Logger } from '../../helpers/index';

const Cart = ({ order, user, setOrderDiscount, offer, GetOffers, FlushOrder }) => {
    const { locale, strings } = useSelector((state) => state.locale);
    const { allLocations } = useSelector(state => state.settings)
    const [code, setCode] = useState(false);
    const dispatch = useDispatch();
    const [collapse, setCollapse] = useState(true)
    const [paymentType, setPaymentType] = useState('cash')
    const [showModal, setModal] = useState(false)
    const navigator = useNavigation()
    const [orderItems, setItems] = useState({})
    const [selectedLocation, setSelectedLocation] = useState(false)
    const [alert, setAlert] = useState(false);
    const [disabled, toggleDisabled] = useState(false);
    const [selectLocation, showSelectLocation] = useState(false)
    let receipt = calcTotalCost(order?.items, offer, selectedLocation?.fees, selectedLocation?.freeDelivery)

    const [dates, setDates] = useState([])

    useEffect(() => {
        if (offer?.code) {
            setOrderDiscount(offer)
        }
    }, [offer])


    useEffect(() => {
        let { items } = order || {}
        switch (order?.location?.type) {
            case 'delivery':
                switch (receipt?.orderType) {
                    case 'mix': case 'sameDay':
                        setItems({ sameDay: Object.values(items || {}) })
                        toggleDisabled(!!dispatch(CheckStoreClosed()))
                        break;
                    default:
                        toggleDisabled(false)
                        let sameDay = items && Object.values(items || {})?.filter(r => r.sameDay)
                        let scdualed = items && Object.values(items || {})?.filter(r => !r.sameDay)
                        setItems({ sameDay, scdualed })
                        break;
                }
                break;
            default:
                setItems({ sameDay: Object.values(items || {}) })
                break;
        }


    }, [order?.items, offer, order?.location?.type, allLocations])



    useEffect(() => {
        if (!user?.locations) {
            return navigator.navigate('Choose your location')
        }
    }, [user?.locations])

    const headerHeight = useHeaderHeight()

    useEffect(() => {
        (async () => {
            if (order?.location) {
                if (allLocations?.[order?.location?.locationId]) {
                    setSelectedLocation(allLocations[order?.location?.locationId])
                } else {
                    setSelectedLocation(false)
                    setAlert({
                        title: strings.alert,
                        msg: strings?.['Sorry, Currently we are not serving this area!'],
                        confirmText: 'Change address',
                        onCancel: () => {
                            navigator.goBack()
                            setAlert(false)
                        },
                        alert: true
                    })
                }
            }
        })()
    }, [order?.location, allLocations])


    useEffect(() => {
        let weekDays = [{ Key: 'Sunday', Label: 'Sun' },
        { Key: 'Monday', Label: 'Mon' },
        { Key: 'Tuesday', Label: 'Tue' },
        { Key: 'Wednesday', Label: 'Wed' },
        { Key: 'Thursday', Label: 'Thu' },
        { Key: 'Friday', Label: 'Fri' },
        { Key: 'Saturday', Label: 'Sat' }]
        let dates = []
        let step = moment()
        if (selectedLocation?.timings) {
            for (var i = 0; i < 14; i++) {
                let weekDay = weekDays[step.day()]
                let dayTimming = selectedLocation?.timings[weekDay.Key];
                let { isOpen } = dayTimming;
                let buffer = selectedLocation?.buffer || 60;
                let today = moment().date() === step.date();
                let slots = dayTimming.timings?.reduce((val, rec) => {
                    let start = moment(rec.startTime)
                    let end = moment(rec.endTime)
                    if (today) {
                        let nowTime = moment(`1955-01-05T${moment().add(buffer, 'minutes').format('HH:mm:ss')}`);
                        if (nowTime.isBefore(start)) {
                            val.push({
                                start, end
                            })
                        }
                    } else {
                        val.push({
                            start, end
                        })
                    }
                    return val
                }, [])

                dates.push({
                    id: i,
                    date: step.format('DD'),
                    weekDay: weekDay.Label,
                    dateTimestamp: step.valueOf(),
                    slots: isOpen ? slots : []
                })

                step.add(1, 'day')
            }
        }
        setDates(dates)
        dispatch(SetOrderDeliveryDate(false))

    }, [selectedLocation?.timings]);

    useEffect(() => {
        if (!order?.date?.date && dates?.length) {
            dispatch(SetOrderDeliveryDate(getFirstAvailableDayAndSlot(dates)))
        }
    }, [dates])
    const getFirstAvailableDayAndSlot = (dates) => {
        for (let i = 0; i < dates?.length; i++) {
            const date = dates?.[i];
            if (date?.slots?.length > 0) {
                const slot = date?.slots?.[0];
                return { date, slot };
            }
        }

        return false
    };
    return (
        <View style={{ flex: 1, backgroundColor: backgroundColor }}>
            {
                alert && <Alert {...alert} />
            }
            {
                selectLocation && <OrderOption user={user} dismiss={() => showSelectLocation(false)} />
            }

            <KeyboardAvoidingView style={{ flex: 1 }} keyboardVerticalOffset={headerHeight} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
                {receipt?.freeDeliveryActive && receipt?.deliveryFees ? <View style={{
                    paddingHorizontal: 16,
                    paddingVertical: 12,
                    backgroundColor: 'rgba(255, 137, 0, 0.05)',
                    borderColor: 'rgba(182,147,41,.1)',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    <Image
                        fill='#DE8329'
                        svg
                        width={25}
                        height={25}
                        uri={FreeDeliverySVG}
                    />
                    <View style={{ marginHorizontal: 15 }}>
                        <Text style={{
                            color: '#000',
                            textAlign: 'left',
                            fontSize: 12,
                            fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular'
                        }}>{strings.needFreeShipping}</Text>

                        <Text style={{
                            color: '#000',
                            textAlign: 'left',
                            fontSize: 14,
                            fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold',
                        }}>{strings.add} {(selectedLocation?.freeDelivery - receipt.subFreeDelivery).toFixed(2)} {strings.forFreeShipping}</Text>

                    </View>
                </View> : <></>}
                <ScrollView showsHorizontalScrollIndicator={false}  >

                    <View style={{ paddingVertical: 32, paddingHorizontal: 16, width: '100%' }}>
                        <Text style={{ textAlign: 'left', fontSize: 18, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', paddingBottom: 16 }}>{order?.location?.type === 'pickup' ? strings.pickupFrom : strings.deliveringTo}</Text>
                        <LocationCard data={order?.location} user={user} select />
                    </View>
                    {
                        orderItems?.sameDay?.length ? <View style={{ paddingHorizontal: 16, width: '100%' }}>
                            <Text style={{ textAlign: 'left', fontSize: 18, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{order?.location?.type === 'delivery' ? strings.fastDelivery : strings?.Items}</Text>
                            {
                                order?.location?.type === 'delivery' ?
                                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 16, paddingTop: 8 }}>
                                        <Image
                                            fill={'#DE8329'}
                                            svg
                                            uri={Watch}
                                            width={10}
                                            height={10}
                                        />
                                        <Text style={{ color: '#DE8329', paddingHorizontal: 4, fontSize: 12, fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular' }}>{strings.estDelivery}</Text>
                                        <Text style={{ color: '#DE8329', fontSize: 12, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{(selectedLocation?.deliveryTime?.value || '') + " " + (selectedLocation?.deliveryTime?.label || '')}</Text>
                                    </View>
                                    :
                                    <View style={{ padding: 4 }} />
                            }

                            {
                                orderItems?.sameDay?.map(rec =>
                                    <View key={rec.id} style={{ paddingBottom: 16 }}>
                                        <CartCard data={rec} />
                                    </View>

                                )
                            }
                        </View>
                            :
                            <View />
                    }
                    {
                        orderItems?.scdualed?.length ? <View style={{ paddingHorizontal: 16, paddingTop: 16, width: '100%' }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <Text style={{ textAlign: 'left', fontSize: 18, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{strings?.["Scheduled Delivery"]}</Text>
                                <TouchableOpacity onPress={() => setModal(true)}>
                                    <Text style={{ color: redColor, textDecorationLine: 'underline', fontSize: 12, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{strings.scheduleDelivery}</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 16, paddingTop: 8 }}>
                                <Image
                                    fill={'#DE8329'}
                                    svg
                                    uri={Watch}
                                    width={10}
                                    height={10}
                                />
                                <Text style={{ color: '#DE8329', paddingHorizontal: 4, fontSize: 12, fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular' }}>{strings.estDelivery}</Text>
                                <Text style={{ color: '#DE8329', fontSize: 12, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{translateSelectedSlot(order?.date) || ""}</Text>
                            </View>
                            {
                                orderItems?.scdualed?.map(rec =>
                                    <View key={rec.id} style={{ paddingBottom: 16 }}>
                                        <CartCard data={rec} />
                                    </View>

                                )
                            }
                        </View>
                            :
                            <View />
                    }


                    <View style={{ paddingHorizontal: 16, paddingTop: 16, width: '100%' }}>
                        <Text style={{ textAlign: 'left', fontSize: 18, paddingBottom: 8, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{strings.paymentMethod}</Text>
                        {
                            [{ id: 'cash', delivery: 'Cash On Delivery', pickup: 'Cash On Pickup' }, { id: 'card', delivery: 'Card On Delivery (POS Machine)', pickup: 'Card On Pickup (POS Machine)' }].map(rec =>
                                <TouchableOpacity
                                    onPress={() => setPaymentType(rec.id)}
                                    key={rec.id} style={{ flexDirection: 'row', paddingVertical: 8, alignItems: 'center' }}>
                                    <Image
                                        svg
                                        width={15}
                                        height={15}
                                        uri={paymentType === rec.id ? FilledDot : Dot}
                                        fill={redColor}
                                    />
                                    <Text style={{
                                        fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold',
                                        fontSize: 12,
                                        paddingHorizontal: 6
                                    }}>
                                        {strings[order?.location?.type === 'pickup' ? rec.pickup : rec.delivery]}
                                    </Text>

                                </TouchableOpacity>
                            )
                        }
                    </View>

                    {
                        order?.discount ?
                            <View style={{
                                marginVertical: 24,
                                marginHorizontal: 16,
                                paddingHorizontal: 14,
                                paddingVertical: 10,
                                borderRadius: 8,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                height: 48,
                                backgroundColor: mainColor
                            }}>
                                <Text style={{ fontSize: 18 }}>{order?.discount?.code}</Text>
                                <TouchableOpacity onPress={() => {
                                    setOrderDiscount(false)
                                    GetOffers(false)

                                }}>
                                    <CustomImage
                                        svg
                                        width={20}
                                        height={20}
                                        uri={Trash}
                                        fill={redColor}

                                    />
                                </TouchableOpacity>
                            </View>
                            :


                            <View style={{
                                height: 48,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginHorizontal: 16,
                                marginVertical: 30,
                                flexDirection: 'row',
                                overflow: 'hidden',
                                backgroundColor: '#fff',
                                borderRadius: 8,

                            }}>
                                <TextInput
                                    onChangeText={(text) => setCode(text)}
                                    placeholder={strings.enterPromocode}
                                    style={{ outline: 'none', height: 60, paddingHorizontal: 8, flex: 2, textAlign: locale === 'en' ? 'left' : 'right' }}
                                />
                                <TouchableOpacity
                                    onPress={async () => {
                                        let res = await applyPromo(code)
                                        if (res?.error) {
                                            setAlert({
                                                title: strings.alert,
                                                msg: res?.error,
                                                confirmText: strings.completeProfile,
                                                onCancel: () => {
                                                    if (alert?.navigate) {
                                                        showSelectLocation(true)
                                                    }
                                                    setAlert(false)
                                                },
                                                alert: true
                                            })
                                        } else if (res) {
                                            GetOffers(res)
                                            setOrderDiscount(res)
                                        }
                                    }}
                                    style={{ height: '100%', borderLeftColor: '#DDE9F1', borderLeftWidth: 1, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <Text style={{ fontSize: 12, color: redColor, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{strings.applyPromo}</Text>
                                </TouchableOpacity>
                            </View>

                    }



                </ScrollView>
            </KeyboardAvoidingView>
            <View style={{
                backgroundColor: backgroundColor,
                paddingVertical: 16,
                shadowColor: '#00000030',
                shadowOffset: {
                    width: 0,
                    height: 3
                },
                elevation: 20,
                shadowRadius: 5,
                shadowOpacity: 1.0,
                // display: 'flex',
                // flexDirection: 'column',
                // alignItems: '',
                // justifyContent: 'space-between'
            }}>
                <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}>
                    <TouchableOpacity onPress={() => {
                        setCollapse(!collapse)
                    }}>
                        <CustomImage
                            svg
                            width={25}
                            height={25}
                            uri={collapse ? collapseSVGUP : collapseSVG}
                            fill={redColor}

                        />
                    </TouchableOpacity>
                </View>
                {!collapse ? <View style={{ paddingHorizontal: 16 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text style={{ fontSize: 12, fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Medium' }}>{strings.subTotal}</Text>
                        <Text style={{ fontSize: 13, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{strings.AED} {Number(receipt.sub).toFixed(2)}</Text>
                    </View>
                    {/* <View style={{ flexDirection: 'row', paddingVertical: 4, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text style={{ fontSize: 12, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{strings.tax}</Text>
                        <Text style={{ fontSize: 13, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{strings.AED} {Number(receipt.tax).toFixed(2)}</Text>
                    </View> */}
                    {receipt?.deliveryFees && receipt.sub !== "0.00" ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text style={{ fontSize: 12, fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Medium', }}>{strings.Delivery}</Text>
                        <Text style={{ fontSize: 13, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{strings.AED} {Number(receipt?.deliveryFees).toFixed(2)}</Text>
                    </View> :
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontSize: 12, fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Medium' }}>{strings.Delivery}</Text>
                            <Text style={{ fontSize: 13, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', color: greenColor }}>{strings.free}</Text>
                        </View>}
                    {
                        receipt.deductedValue &&
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontSize: 12, fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Medium', color: greenColor }}>{strings.discount} {`(${receipt.code})`}</Text>
                            <Text style={{ fontSize: 13, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', color: greenColor }}>{strings.AED} -{Number(receipt.deductedValue).toFixed(2)}</Text>
                        </View>
                    }
                    <View style={{ width: '100%', height: 1, backgroundColor: 'lightgray', marginVertical: 8 }} />
                    <View style={{
                        flexDirection: 'row',
                        borderTopWidth: 1,
                        borderTopColor: "#F5F5F5",
                        paddingTop: 8,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 30
                    }}>
                        {/* <Text style={{ fontSize: 14, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{strings.total}</Text> */}
                        <Text style={{ fontSize: 14, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{strings.total} <Text style={{ fontSize: 10, color: colorTextGrey, fontFamily: locale === 'en' ? 'Poppins-Light' : 'Tajawal-Light' }}>{strings.allPricesIncludeVAT}</Text></Text>

                        <Text style={{ fontSize: 14, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{receipt.sub !== "0.00" ? <Text>{strings.AED} {receipt.total}</Text> : '--'}</Text>
                    </View>
                </View> : <View style={{ paddingHorizontal: 16 }}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 30
                    }}>
                        <Text style={{ fontSize: 14, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{strings.total} <Text style={{ fontSize: 10, color: colorTextGrey, fontFamily: locale === 'en' ? 'Poppins-Light' : 'Tajawal-Light' }}>{strings.allPricesIncludeVAT}</Text></Text>
                        {/* <Text style={{ fontSize: 14, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{strings.AED} {receipt.total}</Text> */}
                        <Text style={{ fontSize: 14, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{receipt.sub !== "0.00" ? <Text>{strings.AED} {receipt.total}</Text> : '--'}</Text>

                    </View>
                </View>}
                <ActionBtn
                    fromCart={true}
                    title={strings.completeOrder}
                    disabled={(selectedLocation?.minOrder && Number(receipt.total) < Number(selectedLocation?.minOrder)) || receipt.sub === "0.00"}
                    onPress={async () => {
                        if (disabled) {
                            let counter = dispatch(CheckStoreClosed())
                            dispatch(SetAlertMessage({
                                title: 'Sorry!',
                                msg: `We cannot accept orders at the moment, you can place your order after ${formatTime(counter)}`,
                                confirmText: 'Got it',
                                alert: true,
                                onCancel: () => {
                                    dispatch(SetAlertMessage(false))
                                }
                            }))
                            return

                        }

                        setAlert({
                            title: strings?.['Order Confirmation'],
                            msg: strings?.['Please confirm your intent to proceed with placing this order.'],
                            confirmText: strings?.['Place Order'],
                            onCancel: () => {
                                setAlert(false)
                            },
                            onConfirm: () => {

                                if (order?.location && receipt && user && order?.items) {
                                    let id = firestore().collection('orders').doc().id;
                                    let selectdDate = order?.date
                                    let userAddress = JSON.parse(JSON.stringify(order?.location))
                                    userAddress.geometry = userAddress?.geometry?.location || '';
                                    delete userAddress?.params;
                                    delete userAddress?.deliveryTime;
                                    delete userAddress?.timings;
                                    let post = {
                                        id,
                                        lang: locale || 'en',
                                        orderNumber: makeid(6),
                                        created: Date.now(),
                                        address: userAddress,
                                        type: userAddress?.type || 'delivery',
                                        paymentType: paymentType,
                                        items: Object.values(order?.items || {}).map(rec => {
                                            let item = {};
                                            ["name", "price", "key", "sameDay", "SKU", "count", "selectedModifiers", "unit"].map(key => {
                                                if (rec[key]) {
                                                    item[key] = rec[key]
                                                }
                                            })
                                            return item
                                        }),
                                        customer: {
                                            name: user?.name || "",
                                            uid: user?.uid || "",
                                            number: user?.phoneNumber || "",
                                        },
                                        receipt,
                                        status: 'new'
                                    }
                                    if (selectdDate) {
                                        post.date = {
                                            date: selectdDate?.date?.dateTimestamp,
                                            slot: {
                                                start: moment(selectdDate.slot.start).valueOf(),
                                                end: moment(selectdDate.slot.end).valueOf()
                                            }
                                        }
                                    }
                                    firestore().collection('orders').doc(id).set(post).then(() => {
                                        navigator.replace('Status Page', { data: post })
                                        FlushOrder()
                                    }).catch(error => {
                                        Logger(error)
                                        setAlert({
                                            title: strings.alert,
                                            msg: 'Somthing went wrong, Please try again or contact us',
                                            confirmText: 'Got it',
                                            onCancel: () => {
                                                setAlert(false)
                                            },
                                            alert: true
                                        })
                                    })
                                }
                                setAlert(false)
                            }
                        })
                        return

                    }} />
                {selectedLocation?.minOrder && Number(receipt.total) < Number(selectedLocation?.minOrder) ? <View style={{ paddingHorizontal: 16, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ color: '#DE8329', fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular' }}>{strings.minOrderIs}</Text>
                    <Text style={{ color: '#DE8329', paddingHorizontal: 4, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{selectedLocation?.minOrder + " " + strings.AED} </Text>
                    <Text style={{ color: '#DE8329', fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular' }}>{strings.toThisArea}</Text>
                </View> : <></>}
            </View>

            {
                showModal && <SelectTime data={dates} dismiss={() => setModal(false)} />
            }
        </View >

    );
};
const mapStateToProps = ({ order, user, offer }) => {
    return { order, user, offer }
}
export default connect(mapStateToProps, { setOrderDiscount, GetOffers, FlushOrder, SetOrderDeliveryDate })(Cart);