import React from 'react';
import { Modal, View, TouchableOpacity, Platform, Dimensions } from 'react-native';
import { useSelector } from 'react-redux';
// import { TouchableOpacity } from 'react-native-gesture-handler';
import { redColor, secColor } from '../../constants/color';
import { Text } from '../shared/custom'

const Alert = ({ title = 'title', cancelText = 'dismiss', confirmText = '', onCancel, onConfirm, PhoneInputComponent }) => {
    const { locale, strings } = useSelector((state) => state.locale);

    return (
        <Modal
            // animationType='slide'
            presentationStyle='overFullScreen'
            transparent={true}
            visible={true}
        >

            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)', alignItems: 'center', width: '100%', height: '100%', width: Dimensions.get('window').width > 900 ? '30%' : '100%' }}>
                <View style={{ backgroundColor: '#fff', width: '90%', borderRadius: 16 }}>
                    <View style={{ zIndex: 10000, justifyContent: 'center', alignItems: 'center', paddingVertical: 35, paddingHorizontal: 16 }}>
                        <Text style={{ color: '#000', fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', fontSize: 18 }}> {title}</Text>
                        {PhoneInputComponent}
                    </View>
                    <View style={{ zIndex: 0, flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', backgroundColor: secColor, borderBottomStartRadius: 16, borderBottomEndRadius: 16 }}>

                        <View style={{ backgroundColor: '#F5F5F5', width: '50%', borderBottomStartRadius: 16, borderBottomEndRadius: 0 }}>
                            <TouchableOpacity style={{ paddingVertical: 16, zIndex: 0 }} onPress={onCancel}>
                                <Text style={{ fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', color: '#000000', textAlign: 'center' }}>{strings[cancelText]}</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{ backgroundColor: redColor, width: '50%', borderBottomEndRadius: 16 }}>
                            <TouchableOpacity style={{ paddingVertical: 16, zIndex: 0 }} onPress={onConfirm} >
                                <Text style={{ color: "#fff", textAlign: 'center', fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{confirmText}</Text>
                            </TouchableOpacity>
                        </View>


                    </View>
                </View>
            </View>

        </Modal>
    );
};

export default Alert;