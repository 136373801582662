import { View } from 'native-base';
import React from 'react';
import { useSelector } from 'react-redux';
import Alert from "./Alert";
const AlertContainer = () => {
    const { alertObject } = useSelector(state => state.settings)
    return (
        alertObject ?
            <Alert {...alertObject} />
            :
            <View />
    );
};

export default AlertContainer;