import React, { useState, useEffect } from 'react';
import { Modal, View, TouchableOpacity, FlatList, Dimensions } from 'react-native';
import { colorTextDark, mainColor, redColor, secColor } from '../../constants/color';
import { CloseBtn } from '../../constants/imgs';
import CustomImage from '../shared/customImg'
import { Text } from '../shared/custom'
import moment from 'moment';
import ActionBtn from '../shared/ActionBtn';
import { connect, useSelector } from 'react-redux';
import { SetOrderDeliveryDate } from '../../reducers/order'
const DateRow = ({ data, update, selectedDate }) => {
    const { locale, strings } = useSelector((state) => state.locale);
    return (
        <TouchableOpacity
            onPress={() => update(data)}
            style={{
                width: 65,
                height: 65,
                marginRight: 8,
                backgroundColor: mainColor,
                borderRadius: 8,
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 1,
                borderColor: selectedDate.id === data.id ? redColor : colorTextDark,
              

            }}>
            <Text style={{
                fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', fontSize: 10, color: selectedDate.id === data.id ? redColor : colorTextDark,
            }}>{data?.weekDay}</Text>
            <Text style={{
                fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', fontSize: 14, paddingTop: 6, color: selectedDate.id === data.id ? redColor : colorTextDark,
            }}>{data?.date}</Text>

        </TouchableOpacity>
    )
}
const TimeRow = ({ data, update, selectedTime }) => {
    const { locale, strings } = useSelector((state) => state.locale);

    return (
        <TouchableOpacity
            onPress={() => update(data)}
            style={{
                marginRight: 8,
                height: 34,
                paddingHorizontal: 16,
                backgroundColor: mainColor,
                borderRadius: 8,
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 1,
                borderColor: selectedTime === data ? redColor : colorTextDark,
               

            }}>
            <Text
                style={{ fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', fontSize: 10, color: selectedTime === data ? redColor : colorTextDark, }}>
                {[data.start?.format('hh:mm A'), data.end?.format('hh:mm A')].join(' - ')}
            </Text>
        </TouchableOpacity>
    )
}
const SelectTime = ({ dismiss, data, SetOrderDeliveryDate }) => {
    const [selectedTime, setSelectedTime] = useState(false)
    const [selectedDate, setSelectedDate] = useState(false)
    const { locale, strings } = useSelector((state) => state.locale);
    return (
        <View>
            <Modal animationType='slide'
                presentationStyle='overFullScreen'
                transparent={true}
                visible={true}
            >
                <View style={{ display: 'flex', flexDirection: 'row', position: 'absolute', bottom: 0, justifyContent: 'center', alignItems: 'flex-end', width: '100%' }}>
                    <View style={{
                        backgroundColor: mainColor,
                        width: '100%',
                        borderRadius: 16,
                        paddingBottom: 22,
                        alignItems: 'flex-start',
                        shadowColor: '#000000',
                        shadowRadius: 6,
                        elevation: 4,
                        shadowOffset: { width: 2, height: 2 },
                        shadowOpacity: 0.4
                    }}>
                        <Text style={{ fontSize: 22, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', paddingTop: 24, paddingHorizontal: 16, textAlign: 'left' }}>
                            {strings.chooseDateTime}
                        </Text>
                        <TouchableOpacity onPressIn={() => dismiss()} style={{ position: 'absolute', padding: 16, right: 0, zIndex: 99 }}>
                            <CustomImage width={30} height={30} fill={redColor} svg uri={CloseBtn} />
                        </TouchableOpacity>
                        <FlatList
                            contentContainerStyle={{ paddingTop: 20, paddingHorizontal: 16 }}
                            data={data}
                            horizontal
                            showsHorizontalScrollIndicator={false}
                            renderItem={(i) => <DateRow key={i.item.id} selectedDate={selectedDate} data={i.item} update={(id) => { setSelectedDate(id); setSelectedTime(false) }} />}
                            keyExtractor={item => item.id}

                        />
                        {
                            selectedDate?.slots?.length ?
                                <FlatList
                                    style={{
                                        paddingVertical: 24
                                    }}
                                    contentContainerStyle={{ paddingHorizontal: 16, paddingBottom: 100 }}
                                    data={selectedDate?.slots}
                                    horizontal
                                    showsHorizontalScrollIndicator={false}
                                    renderItem={(i) => <TimeRow key={i.index} selectedTime={selectedTime} data={i.item} update={(id) => setSelectedTime(id)} />}
                                    keyExtractor={item => item.id}

                                />
                                :
                                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingVertical: 24, width: '100%', paddingBottom: 122 }}>
                                    {
                                        selectedDate ?
                                            <Text textAlign='center' style={{ textAlign: 'center' }}>{strings?.["No Slots available for selected date"]}</Text>
                                            :
                                            <View />
                                    }
                                </View>
                        }

                        <View >
                            <ActionBtn title={strings?.['Select Date']} disabled={!selectedDate || !selectedTime} onPress={() => {
                                SetOrderDeliveryDate({ date: selectedDate, slot: selectedTime });
                                dismiss()
                            }}
                            />
                        </View>

                    </View>
                </View>
            </Modal>
        </View>
    );
};
const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, { SetOrderDeliveryDate })(SelectTime);