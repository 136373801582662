import React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const PhoneNumber = (props) => {
    return (
        <PhoneInput {...props}
        />
    );
};
export default PhoneNumber;