import React from 'react';
import { Text as TextCopmnet } from 'react-native'
import { colorTextDark } from '../../constants/color';
export const Text = (props) => {
    const capitalize = (s) => {
        if (typeof s !== 'string') return props.children
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    }
    return (
        <TextCopmnet
            numberOfLines={props.numberOfLines}
            style={{ color: colorTextDark, ...props.style }}>
            {capitalize(props.children)}

            {/* {typeof props.children === 'string' ? props.children.charAt(0)?.toUpperCase() + props.children.slice(1).toLowerCase() : props.children} */}
        </TextCopmnet>
    );
};
