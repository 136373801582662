export default {
  orderFromTayba: 'اطلب من طيبة',
  shopByCategory: 'تسوق حسب الفئة',
  search: 'بحث',
  showAll: 'إظهار الكل ←',
  shopByBrand: 'تسوق وفق الماركة',
  deliveringTo: 'التوصيل إلى: ',
  Delivery: 'توصيل',
  Pickup: 'استلام',
  newArrival: 'اضيف حديثا',
  Tayba: 'طيبة',
  AED: 'درهم',
  deliveringTo: 'التوصيل إلى',
  readyToEat: 'العناصر الجاهزة للأكل',
  fastDelivery: 'توصيل سريع',
  estDelivery: 'مقدر. توصيل:',
  otherItems: 'عناصر أخرى',
  scheduleDelivery: 'جدولة التسليم',
  paymentMethod: 'طريقة الدفع او السداد',
  chooseDateTime: 'اختر التاريخ والوقت',
  'Cash On Delivery': 'الدفع عند الاستلام',
  'Card On Delivery (POS Machine)': 'بطاقة عند الاستلام (آلة نقاط البيع)',
  'Cash On Pickup': 'الدفع عند التسليم',
  'Card On Pickup (POS Machine)': 'بطاقة عند التسليم (آلة نقاط البيع)',
  enterPromocode: 'إدخال الرمز الترويجي',
  applyPromo: "تطبيق",
  subTotal: 'المجموع الفرعي',
  tax: 'الضريبة (0.5٪)',
  discount: 'خصم',
  total: 'المجموع',
  completeOrder: 'اكمل الطلب',
  change: 'تغيير',
  searchPlace: 'ابحث عن مكان',
  confirmLocation: 'تأكيد الموقع',
  yourName: 'اسمك',
  yourNumber: 'رقم الهاتف',
  yourEmail: 'بريدك الالكتروني',
  area: 'المنطقة',
  street: 'الشارع',
  buildingName: 'اسم المبنى',
  floor: 'الدور',
  flat: 'الشقة',
  Home: 'منزل',
  Work: 'عمل',
  Other: 'آخر',
  saveAddress: 'حفظ العنوان',
  generalInfo: 'معلومات عامة',
  locations: 'المواقع',
  favourites: 'المفضلة',
  languages: 'اللغات',
  orders: 'الطلبات',
  addLocation: 'إضافة الموقع',
  results: 'نتائج',
  profile: 'الملف الشخصي',
  favouritesList: 'قائمة المفضلة',
  previousOrders: 'الطلبات السابقة',
  myAddresses: 'عناويني',
  getInTouch: 'تواصل معنا',
  logOut: 'تسجيل خروج',
  logoutMsg: 'تريد الخروج من طيبة',
  logoutTitle: 'تسجيل خروج؟',
  cancel: 'إلغاء',
  general: 'عامة',
  settings: 'إعدادات',
  otherItems: 'أشياء أخرى',
  "Scheduled Delivery": 'التوصيل المجدول',
  yourOrder: 'طلبك',
  statusPage: 'حالة الطلب',
  chat: 'محادثة',
  cart: 'السلة',
  warning: 'تحذير',
  cancelOrder: 'الغاء الطلب',
  areYouSure: 'هل أنت متأكد؟',
  quantity: 'الكمية',
  readyToEat: 'جاهز للاكل',
  allPricesIncludeVAT: 'تشمل جميع الأسعار ضريبة القيمة المضافة',
  choose: 'اختر',
  chooseAtLeaset: 'اختر على الأقل',
  chooseUpto: 'اختر لغاية',
  ingredients: 'مكونات من سوقنا',
  addToCart: 'أضف إلى السلة',
  required: 'مطلوب',
  alert: 'انذار',
  completeProfile: 'اكمل الملف الشخصي',
  completeProfileMsg: 'يرجى إكمال ملف التعريف الخاص بك قبل الطلب',
  login: 'تسجيل الدخول',
  loginMsg: 'يرجى تسجيل الدخول لاستخدام هذه الميزة',
  removeItem: 'حذف',
  removeItemMsg: 'هل أنت متأكد أنك تريد حذف هذا العنصر؟',
  facebookSignin: 'الدخول باستخدام الفيسبوك',
  googleSignin: 'الدخول باستخدام جوجل',
  appleSignin: 'الدخول باستخدام ابل',
  enterMobileNumber: 'أدخل رقم هاتفك للمتابعة',
  phoneNumber: 'رقم الهاتف',
  continue: 'المتابعة',
  enterValidNumber: 'الرجاء إدخال رقم هاتف صحيح',
  orVia: 'او عن طريق',
  off: 'خصم',
  home: 'الصفحة الرئيسية',
  selectLocation: 'اختر موقعك',
  verifyPhoneNumber: 'تحقق من الرقم',
  verifyNumberMsg: 'لم يتم التحقق من رقم الهاتف المحمول. يرجى التحقق من خلال إرسال OTP إلى رقمك',
  sendOTP: 'إرسال OTP',
  noOrders: 'لا توجد طلبات',
  noFavourites: 'لا مفضلات',
  noLocations: 'لا توجد مواقع مضافة',
  noResults: 'لا توجد نتائج',
  dismiss: 'رجوع',
  minOrderIs: 'الحد الأدنى للطلب هو',
  toThisArea: 'لهذه المنطقة',
  changeEmail: 'تغيير البريد الالكتروني',
  resetPassword: 'إعادة تعيين كلمة المرور',
  name: 'الاسم',
  gender: 'الجنس',
  DOB: 'تاريخ الميلاد',
  saveInfo: 'احفظ المعلومات',
  order: 'الطلب#',
  placedOn: 'تم الطلب في',
  status: 'الحالة',
  reviewOrder: 'قيم هذا الطلب',
  viewDetails: 'عرض التفاصيل ←',
  reviewYourOrder: 'قيم طلباتك',
  'Overall order experience': 'تجربة النظام العام',
  'Delivery on time': 'التسليم في الوقت المحدد',
  'Rate products': 'قيم المنتجات',
  writeFeedback: 'اكتب ملاحظاتك',
  submitReview: 'إرسال المراجعة',
  Male: 'ذكر',
  Female: 'انثى',
  addItemsMsg: 'يرجى إضافة منتجات إلى عربة التسوق الخاصة بك أولا!',
  enterVerification: 'الرجاء إدخال رمز التحقق المرسل',
  to: 'إلى',
  DontReceive: 'لم تحصل على رمز!',
  resendIn: 'إعادة إرسال خلال',
  resend: 'إعادة إرسال',
  upto: 'لغاية',
  new: 'قيد الانتظار',
  accepted: 'مقبول',
  active: 'نشط',
  completed: 'اكتمل',
  canceled: 'ملغى',
  rejected: 'رفض',
  changeNumber: 'تغيير رقم الهاتف المحمول',
  confirm: 'تأكيد',
  pickupFrom: 'الاستلام من',

  sorry: 'ناسف!',
  notServingArea: 'نحن لا نخدم المنطقة المختارة في هذا الوقت',
  addNewAddress: 'أضف عنوان جديد +',
  addressDetails: 'تفاصيل العنوان',
  deleteLocation: 'هل أنت متأكد أنك تريد حذف هذا الموقع؟',
  closedMsg: 'خيار الاستلام من طيبة جورميه غير متوفر خارج ساعات العمل',
  addedToCart: 'تمت الإضافة إلى عربة التسوق',
  continueShopping: 'استمر في التسوق',
  viewCart: 'عرض عربة التسوق',
  priceOnSelection: 'السعر حسب الاختيار',
  "Select Date": 'اختيار الموعد',
  "Sorry,This branch is not working at the moment!": 'عذرا ، هذا الفرع لا يعمل في الوقت الحالي!',
  "Sorry, Currently we are not serving this area!": 'عذرا ، حاليا نحن لا نخدم هذه المنطقة!',
  "Change pickup spot": 'تغيير نقطة الالتقاط',
  "Change address": 'غير عنوان التوصيل',
  "Select address": 'حدد العنوان',
  "Please select delivery address": 'الرجاء تحديد عنوان التسليم',
  existedNumber: 'رقم الهاتف هذا قيد الاستخدام بالفعل',
  needFreeShipping: 'هل ما زلت بحاجة إلى شحن مجاني؟',
  add: 'أضف',
  forFreeShipping: 'درهم للشحن المجاني',
  free: 'مجاني',
  "Items": 'الطلبات',
  "Order Confirmation": 'تأكيد الطلب',
  "Please confirm your intent to proceed with placing this order.": 'يرجى تأكيد نيتك في المضي قدمًا في وضع هذا الطلب.',
  "Place Order": 'وضع الطلب',
  "New Version": "إصدار جديد من تطبيق Tayba متاح الآن. يرجى التحديث إلى أحدث إصدار الآن.",
  "Update": 'تحديث',
  "No Slots available for selected date": 'لا توجد فترات زمنية متاحة في التاريخ المحدد',
  "Sorry we cannot accept FAST DELIVERY orders at the moment": 'عذرًا ، لا يمكننا قبول طلبات التسليم السريع في الوقت الحالي',
  "You can place your order after": 'يمكنك تقديم طلبك بعد'




}
