import React from 'react';
import { FlatList, View } from 'react-native';
import { backgroundColor } from '../../constants/color';
import LanguageCard from '../shared/LanguageCard';

const Languages = ({ }) => {

    const DATA = [
        {
            lang: 'en',
            id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
            title: 'English',
        },
        {
            lang: 'ae',
            id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
            title: 'العربية',
        },

    ];


    return (
        <View style={{ flex: 1, backgroundColor: backgroundColor }}>
            <FlatList
                contentContainerStyle={{ width: '100%', paddingTop: 16, paddingHorizontal: 12 }}
                data={DATA}
                numColumns={1}
                showsVerticalScrollIndicator={false}
                renderItem={(i) =>
                    <View key={i.item} style={{ width: '100%' }} >
                        <LanguageCard index={i.item.id} data={i.item} />
                    </View>
                }
                keyExtractor={item => item.id}
            />
        </View>
    );
};

export default Languages;