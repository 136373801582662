import React, { useEffect, useState } from 'react';
import { Image, KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity, View, ActivityIndicator } from 'react-native';
import { backgroundColor, colorTextDark, mainColor, redColor, secColor } from '../../constants/color';
import { TextInput } from 'react-native-paper';
import { heightPercentageToDP } from 'react-native-responsive-screen';
import ActionBtn from '../shared/ActionBtn';
import { Text } from '../shared/custom'
import { auth, firestore, functions } from '../../constants/firebase/config'
import { makeid } from '../../helpers/helpers';
import PhoneInput from '../shared/PhoneInput/index';
import Alert from '../shared/Alert'

import { connect, useDispatch, useSelector } from 'react-redux';
import { SetOrderLocation } from '../../reducers/order';
import { Logger } from '../../helpers/index';

async function verifyPhoneNumber(phoneNumber) {
    let confirmation;
    if (Platform.OS === 'web') {
        confirmation = await auth().signInWithPhoneNumber('+' + phoneNumber, window.recaptchaVerifier).catch(error => {
            Logger(error)
            return false
        });
    } else {
        confirmation = await auth().verifyPhoneNumber(phoneNumber).catch(error => {
            Logger(error)
            console.log("errorerrorerror", error);
            return false
        });;
    }
    return confirmation
}
const LocationDetails = ({ route, navigation, user }) => {
    const { locale, strings } = useSelector((state) => state.locale);
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(false)
    const dispatch = useDispatch()
    const [field, setFields] = useState([
        [{ id: "name", title: strings.yourName }],
        [{ id: "phoneNumber", title: strings.yourNumber }],
        [{ id: "email", title: strings.yourEmail }],
        [{ id: "area", title: strings.area}],
        [{ id: "street", title: strings.street }],
        [{ id: "building", title: strings.buildingName }],
        [{ id: "floor", title: strings.floor },
        { id: 'flat', title: strings.flat }],
    ])


    const [selectedRegon, setSelectedRegon] = useState({})
    const [locationId, setLocationId] = useState({})
    const [userAddress, update] = useState({})
    useEffect(() => {
        let data = route?.params?.data
        let { selectedRegon, locationId } = route.params || {}
        if (data) {
            selectedRegon = {
                details: {
                    formatted_address: data?.formatted_address,
                    geometry: data?.geometry,
                    ...data
                },
                latitude: data?.geometry?.location?.lat,
                longitude: data?.geometry?.location?.lng
            }
            let userAddress = JSON.parse(JSON.stringify(data))

            delete userAddress.formatted_address
            delete userAddress.geometry

            update(userAddress)
        } else {
            userAddress.area = selectedRegon?.address?.area
            userAddress.street = selectedRegon?.address?.street
            update(userAddress)
        }

        setLocationId(locationId)
        setSelectedRegon(selectedRegon)
        let fields = field
        fields = fields.map(r => {
            if (user?.name && r?.[0]?.id === 'name') {
                return false
            }
            if (user?.phoneNumber && auth()?.currentUser?.phoneNumber && r?.[0]?.id === 'phoneNumber') {
                return false
            }
            if (user?.email && r?.[0]?.id === 'email') {
                return false
            }
            return r
        }).filter(r => !!r)
        setFields(fields)

    }, [user])
    useEffect(() => {
        if (Platform.OS === 'web' && window.document.getElementById('recaptcha-container')) {
            window.recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': function (response) {
                }
            });
            try {
                window.recaptchaVerifier.render()
            } catch (error) {
                setAlert(false)
                setLoading(false)
            }
        }
    }, [])
    return (
        <KeyboardAvoidingView keyboardVerticalOffset={100} behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={{ flex: 1, backgroundColor: backgroundColor }}>
            {
                alert && <Alert
                    alert
                    title={alert?.title}
                    // confirmText={strings.completeProfile}
                    msg={alert?.msg}
                    cancelText={alert?.text}
                    onCancel={async () => {
                        if (alert.title === strings.alert) {
                            setAlert(false)
                            setLoading(false)
                        } else {
                            setAlert(false)
                            setLoading(true)
                            if (userAddress.phoneNumber) {
                                let userExists = await functions().httpsCallable('checkExistingUser')({
                                    phoneNumber: userAddress.phoneNumber
                                })
                                if (userExists?.data?.existed) {
                                    setAlert({ title: strings.alert, msg: strings.existedNumber, text: 'dismiss' })
                                } else {
                                    try {
                                        let confirmation = await verifyPhoneNumber(userAddress.phoneNumber)
                                        if (confirmation) {
                                            navigation.navigate('Verify', { confirmation, linkMobile: true, phoneNumber: userAddress.phoneNumber })
                                        } else {
                                            setAlert({ title: strings.alert, msg: strings.enterValidNumber, text: 'dismiss' })
                                        }
                                        setLoading(false)

                                    } catch (err) {
                                        Logger(err)
                                        console.log('errerrerr', err);
                                        setAlert({ title: strings.alert, msg: strings.enterValidNumber, text: 'dismiss' })
                                        setLoading(false)
                                    }
                                }

                            } else {
                                let err = new Error('no number entered')
                                Logger(err)
                                setAlert({ title: strings.alert, text: 'dismiss', msg: strings.enterValidNumber })
                                setLoading(false)
                            }
                        }
                    }
                    }
                />
            }
            <View nativeID='recaptcha-container' />
            {loading && <View style={{
                // backgroundColor: redColor,
                padding: 16,
                borderRadius: 10,
                position: 'absolute',
                zIndex: 10000,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%'

            }}>
                <ActivityIndicator size="large" color={redColor} />
            </View>}
            <ScrollView showsHorizontalScrollIndicator={false}  >
                <View style={{ paddingHorizontal: 16, height: heightPercentageToDP('25%'), paddingVertical: 12 }}>
                    <Image
                        // width={165}
                        height={'100%'}
                        style={{
                            // width: 165,
                            height: '100%',
                            borderRadius: 8
                        }}
                        resizeMode="cover"
                        source={{ uri: `https://maps.googleapis.com/maps/api/staticmap?center=${selectedRegon?.latitude},${selectedRegon?.longitude}&zoom=13&size=600x300&sensor=false&key=AIzaSyDKh65DXmzsGhOlqcpDTi_U_B1autbsgqc` }}
                    />

                </View>
                <View style={{ paddingHorizontal: 16, paddingTop: 16, paddingBottom: 120 }}>

                    {field.map((rec, index) => {
                        return (
                            <View key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', zIndex: Platform.OS === 'web' ? 'auto' : 0 }}>
                                {
                                    rec.map(item => {
                                        return (
                                            item.id === "phoneNumber" ?
                                                <View key={item.id} style={{ direction: 'ltr', width: '100%', zIndex: Platform.OS === 'web' ? 'auto' : 0 }}>
                                                    {
                                                        (Platform.OS === "web" ? <PhoneInput
                                                            containerStyle={{
                                                                width: '100%',
                                                                background: mainColor,
                                                                borderRadius: 6,
                                                                marginBottom: 16,
                                                            }}
                                                            inputStyle={{
                                                                width: '100%',
                                                                height: 50,
                                                                background: mainColor,
                                                                borderColor: mainColor,
                                                                color: '#000000'
                                                            }}
                                                            dropdownStyle={{
                                                                background: mainColor,
                                                                borderColor: mainColor,
                                                                color: '#000000',
                                                                zIndex: 10000,
                                                                // width: '100%'
                                                            }}
                                                            buttonStyle={{
                                                                background: mainColor,
                                                                borderColor: mainColor,
                                                            }}
                                                            regions={['middle-east', 'north-africa']}

                                                            country={'ae'}
                                                            onChange={text => {
                                                                if (Number(text)) {
                                                                    update({ ...userAddress, [item.id]: text?.replace(/^0+/, '') })
                                                                }
                                                            }}
                                                            key={item.id}

                                                        /> : <PhoneInput
                                                            key={item.id}
                                                            defaultCode="AE"
                                                            onChangeFormattedText={(text) => {
                                                                if (Number(text)) {
                                                                    update({ ...userAddress, [item.id]: text?.replace(/^0+/, '') })
                                                                }
                                                            }}
                                                            withShadow={false}
                                                            textInputProps={{

                                                                placeholderTextColor: '#cacaca60',
                                                                // onFocus: _animateFormContainer,
                                                                placeholder: strings.phoneNumber,
                                                                style: {
                                                                    height: Platform.OS === 'ios' ? "auto" : 50,
                                                                    color: '#000',
                                                                    fontSize: 16,
                                                                    textAlign: locale === 'en' ? 'left' : 'right',
                                                                },
                                                                selectionColor: '#000',
                                                                width: '100%'
                                                            }}
                                                            containerStyle={{
                                                                borderRadius: 6,
                                                                overflow: 'hidden',
                                                                width: '100%',
                                                                color: '#000',
                                                                marginBottom: 16,
                                                                direction: locale === 'en' ? 'ltr' : 'rtl',

                                                                backgroundColor: mainColor,

                                                            }}
                                                            textContainerStyle={{
                                                                borderLeftWidth: 2,
                                                                borderStyle: 'solid',
                                                                color: '#000',
                                                                height: Platform.OS === 'ios' ? "auto" : 50,
                                                                borderLeftColor: mainColor,
                                                                backgroundColor: mainColor,

                                                            }}
                                                            withDarkTheme={false}
                                                            codeTextStyle={{ color: '#000' }}

                                                        // value={phoneNumber}
                                                        />
                                                        )}
                                                </View>
                                                : <TextInput
                                                    key={item.id}
                                                    onChangeText={(text) => update({ ...userAddress, [item.id]: text })}
                                                    value={userAddress[item.id]}
                                                    disabled={item.disabled}
                                                    theme={{
                                                        dark: true,
                                                        roundness: 8,
                                                        colors: { text: colorTextDark, accent: colorTextDark, primary: redColor, placeholder: '#5E5A65', background: mainColor }
                                                    }}
                                                    style={{ outline: 'none', color: colorTextDark, fontSize: 14, marginBottom: 16, height: 50, borderRadius: 8, width: `${rec?.length > 1 ? 48 : 100}%` }}
                                                    label={item.title}
                                                    underlineColor='transparent'
                                                    underlineColorAndroid='transparent'

                                                />
                                        )

                                    })
                                }

                            </View>
                        )
                    })}
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        {
                            ["Home", 'Work', 'Other'].map(r => <TouchableOpacity
                                onPress={() => update({ ...userAddress, label: r })}
                                key={r} style={{
                                    borderRadius: 8,
                                    paddingHorizontal: 16,
                                    paddingVertical: 8,
                                    borderColor: userAddress.label === r ? redColor : 'transparent',
                                    borderWidth: 1,
                                    marginRight: 6,
                                    marginTop: 14,
                                    backgroundColor: userAddress.label === r ? redColor : mainColor,
                                }}>
                                <Text style={{ color: userAddress.label === r ? '#fff' : '#7C7C7C' }}>{strings[r]}</Text>
                            </TouchableOpacity>)
                        }
                    </View>
                </View>
            </ScrollView>
            <ActionBtn
                disabled={

                    field.reduce((value, current) => {
                        current.map(r => {
                            if (!userAddress[r.id]) {
                                return value = true
                            }
                            return value
                        })
                        return value
                    }, false)
                }
                title={strings.saveAddress}
                onPress={async () => {
                    setLoading(true)
                    if (auth().currentUser) {
                        if (!auth()?.currentUser?.phoneNumber) {
                            setAlert({ title: strings.verifyPhoneNumber, text: 'sendOTP', msg: strings.verifyNumberMsg })
                        } else {
                            let locations = user?.locations || []
                            let data = route?.params?.data
                            let post = {
                                id: data ? userAddress.id : makeid(5),
                                ...userAddress,
                                formatted_address: selectedRegon?.details?.formatted_address,
                                geometry: selectedRegon?.details?.geometry,
                                locationId: data ? userAddress.locationId : locationId,
                                optimized: true
                            };
                            delete post.email;
                            delete post.name;
                            delete post.phoneNumber;
                            if (data) {
                                locations = locations.filter(r => r.id !== data.id)
                            }
                            locations.push(post)

                            firestore().collection('users').doc(auth().currentUser?.uid).update({
                                locations: locations,
                                name: userAddress?.name || user?.name || '',
                                email: userAddress?.email || user?.email || '',
                                phoneNumber: userAddress?.phoneNumber || user?.phoneNumber || '',
                            }).then(() => {

                                dispatch(SetOrderLocation({ ...post, type: 'delivery' }))
                                if (data) {
                                    navigation.goBack()

                                } else {
                                    navigation.pop(2)

                                }
                                setLoading(false)
                            }).catch(err => {
                                console.log('errerr', err);
                                Logger(err)
                                setLoading(false)
                            })
                        }

                    }
                }} />
        </KeyboardAvoidingView>

    );


};
const mapStateToProps = ({ user }) => {
    return { user }
}
export default connect(mapStateToProps, {})(LocationDetails);