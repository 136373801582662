import React, { useEffect, useState } from 'react';
import { FlatList, View,Text } from 'react-native';
import { connect, useSelector } from 'react-redux';
import { backgroundColor, colorTextDark } from '../../constants/color';
import ProductCard from '../shared/productCard';

const Favourits = ({ route, prds }) => {
    let { user } = route?.params || {}
    let [list, setList] = useState([])
    useEffect(() => {
        let favourit = prds.filter(r => (user?.favourit || []).includes(r.id))
        setList(favourit)
    }, [])
    const { strings } = useSelector((state) => state.locale);

    return (
        <View style={{ flex: 1, backgroundColor: backgroundColor }}>
            {list.length ? <FlatList
                contentContainerStyle={{ width: '100%', paddingTop: 16, paddingHorizontal: 12 }}
                data={list}
                numColumns={2}
                showsVerticalScrollIndicator={false}
                renderItem={(i) =>
                    <View key={i.item} style={{ width: '50%' }} >
                        <ProductCard index={i.item.id} data={i.item} />
                    </View>
                }
                keyExtractor={item => item.id}
            /> : <View
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                <Text style={{ fontSize: 18 ,color:colorTextDark}}>{strings.noFavourites}</Text>
            </View>}
        </View>
    );
};
const mapStateToProps = ({ helpers }) => {
    return { prds: helpers?.prds || [] }
}
export default connect(mapStateToProps, {})(Favourits);