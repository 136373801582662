export const mainColor = '#FFFFFF';
export const secColor = '#0D0D0D';
export const redColor = '#E0262D';
export const greenColor = '#28AF33';
export const backgroundColor = '#F8F8F8';
export const colorLightGrey = '#F8F8F8';

export const colorBorder = '#7070707f';
export const colorText = '#555555';
export const colorTextDark = '#2D363E';
export const colorTextBlue = '#55C9E8';
export const colorTextGrey = '#afafaf';
export const colorTextDanger = '#f84848';
export const colorTextComplete = '#00E17A';
