import React, { useEffect, useRef, useState } from 'react';
import { SafeAreaView, ActivityIndicator, View, TouchableOpacity, Platform, ScrollView } from 'react-native'
import { Text } from '../shared/custom';
import Logo from '../../assets/logoTayba.png'

import { colorTextDark, mainColor, redColor, secColor } from '../../constants/color';
import CustomImage from '../shared/customImg'
import { googleLogo, appleLogo, facebookLogo } from '../../constants/imgs';
import { auth } from '../../constants/firebase/config'
import { onAppleButtonPress, onFacebookButtonPress, onGoogleButtonPress } from '../../helpers/index';
import PhoneInput from '../shared/PhoneInput/index';
import Alert from '../shared/Alert'
import { useSelector } from 'react-redux';
import { Logger } from '../../helpers/index';
async function signInWithPhoneNumber(phoneNumber) {
    let confirmation;
    if (Platform.OS === 'web') {
        confirmation = await auth().signInWithPhoneNumber("+" + phoneNumber, window.recaptchaVerifier);
    } else {
        confirmation = await auth().signInWithPhoneNumber(phoneNumber);
    }
    return confirmation
}

const Login = ({ navigation }) => {
    const [phoneNumber, setNumber] = useState('')
    const [alert, setAlert] = useState(false)
    const [loading, setLoading] = useState(false)
    const { locale, strings } = useSelector((state) => state.locale);

    const [LoginOptions, setOptions] = useState([
        { title: strings.facebookSignin, action: onFacebookButtonPress, img: facebookLogo },
        { title: strings.googleSignin, action: onGoogleButtonPress, img: googleLogo }])
    useEffect(() => {
        let options = [];
        if (Platform.OS === 'ios') {
            options = [
                { title: strings.appleSignin, action: onAppleButtonPress, img: appleLogo },
                { title: strings.facebookSignin, action: onFacebookButtonPress, img: facebookLogo },
                { title: strings.googleSignin, action: onGoogleButtonPress, img: googleLogo }]
        } else {
            options = [
                { title: strings.facebookSignin, action: onFacebookButtonPress, img: facebookLogo },
                { title: strings.googleSignin, action: onGoogleButtonPress, img: googleLogo }]
        }
        setOptions(options)
    }, [])
    useEffect(() => {
        if (Platform.OS === 'web' && window.document.getElementById('recaptcha-container')) {
            window.recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': function (response) {
                }
            });
            try {
                window.recaptchaVerifier.render()
            } catch (error) {
                console.log("errorerror", error)
            }
        }
    }, [])
    return (
        <SafeAreaView style={{ flex: 1 }}>
            <View style={{ justifyContent: 'flex-end', flexDirection: 'row', paddingHorizontal: 16, paddingTop: 12 }}>
                <TouchableOpacity onPress={() => {
                    navigation.navigate('HomeScreen')
                }}>
                    <Text style={{ fontSize: 16, fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular' }}>Skip</Text>
                </TouchableOpacity>
            </View>

            <ScrollView showsHorizontalScrollIndicator={false} >
                <View style={{ paddingHorizontal: 16, alignItems: 'center', justifyContent: 'center', minHeight: 750 }}>

                    {
                        alert && <Alert msg={alert?.msg} title={strings.alert} onCancel={() => {
                            setAlert(false)
                            setLoading(false)
                        }} alert />
                    }

                    <View nativeID='recaptcha-container' />
                    <SafeAreaView />
                    <CustomImage uri={Logo} png width={'60%'} height={Platform.OS === 'web' ? '30%' : '35%'} />
                    <Text style={{ fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular', fontSize: 14 }}>{strings.enterMobileNumber}</Text>
                    <View style={{
                        marginTop: 24, marginBottom: 16, width: '100%', zIndex: 10000000,
                        borderColor: colorTextDark,
                        borderRadius: 8,
                        borderWidth: 1,
                        overflow: 'hidden'
                    }}>
                        {Platform.OS === "web" ? <PhoneInput
                            value={phoneNumber}

                            containerStyle={{
                                width: '100%',
                                background: mainColor,
                                borderRadius: 6,
                                direction: 'ltr'
                            }}
                            inputStyle={{
                                width: '100%',
                                height: 50,
                                background: mainColor,
                                borderColor: mainColor,
                                color: colorTextDark
                            }}
                            dropdownStyle={{
                                background: mainColor,
                                borderColor: mainColor,
                                color: colorTextDark,
                                zIndex: 10000,
                                position: 'fixed'
                                // width: '100%'
                            }}
                            buttonStyle={{
                                background: mainColor,
                                borderWidth: 0,
                                borderRightWidth: 1,
                                borderRightColor: '#000',
                            }}
                            regions={['middle-east', 'north-africa']}

                            country={'ae'}
                            onChange={text => {
                                if (Number(text)) {
                                    // this.setState({ phone })
                                    setNumber(text?.replace(/^0+/, ''))
                                }
                            }}
                        />
                            :
                            <PhoneInput
                                defaultCode="AE"
                                onChangeFormattedText={(text) => {
                                    if (Number(text)) {
                                        setNumber(text?.replace(/^0+/, ''))
                                    }
                                }}
                                withShadow
                                textInputProps={{

                                    placeholderTextColor: '#cacaca60',
                                    // onFocus: _animateFormContainer,
                                    placeholder: strings.phoneNumber,
                                    style: {

                                        height: Platform.OS === 'ios' ? "auto" : 50,
                                        color: colorTextDark,
                                        fontSize: 16,
                                        textAlign: locale === 'en' ? 'left' : 'right',
                                    },
                                    selectionColor: colorTextDark,
                                    width: '100%'
                                }}
                                containerStyle={{
                                    borderRadius: 6,
                                    overflow: 'hidden',
                                    width: '100%',
                                    color: colorTextDark,
                                    backgroundColor: mainColor,

                                }}
                                textContainerStyle={{
                                    borderLeftWidth: 1,
                                    borderStyle: 'solid',
                                    color: colorTextDark,
                                    height: Platform.OS === 'ios' ? "auto" : 50,
                                    borderLeftColor: colorTextDark,
                                    backgroundColor: mainColor,

                                }}
                                codeTextStyle={{ color: colorTextDark }}

                                value={phoneNumber}
                            />}

                    </View>

                    <View style={{ width: '100%' }}>

                        {!loading ? <TouchableOpacity
                            onPress={async () => {
                                setLoading(true)
                                if (phoneNumber) {
                                    try {
                                        let confirmation = await signInWithPhoneNumber(phoneNumber)
                                        navigation.navigate('Verify', { confirmation, phoneNumber: phoneNumber })
                                        setLoading(false)

                                    } catch (err) {

                                        setAlert({ msg: strings.enterValidNumber })
                                        setLoading(false)
                                        Logger(err)
                                    }
                                } else {
                                    let err = new Error('no number entered')
                                    Logger(err)
                                    setAlert({ msg: strings.enterValidNumber })
                                    setLoading(false)


                                }


                            }}
                            style={{
                                backgroundColor: redColor,
                                padding: 16,
                                borderRadius: 10,

                            }}>
                            <Text style={{ color: '#fff', textAlign: 'center', fontSize: 16, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', width: '100%' }}>{strings.continue}</Text>
                        </TouchableOpacity> :
                            <View style={{
                                backgroundColor: redColor,
                                padding: 16,
                                borderRadius: 10,

                            }}>
                                <ActivityIndicator size="small" color={mainColor} />
                            </View>}
                    </View>
                    <View style={{ width: '100%', paddingTop: 24, paddingBottom: 32, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <View style={{ height: 1, backgroundColor: '#cacaca', width: '43%' }} />
                        <Text>{strings.orVia}</Text>
                        <View style={{ height: 1, backgroundColor: '#cacaca', width: '43%' }} />

                    </View>
                    {
                        LoginOptions.map((rec) => <View key={rec.title} style={{ width: '100%', paddingBottom: 14 }}>
                            <TouchableOpacity
                                onPress={async () => {
                                    try {
                                        let user = await rec.action();
                                        navigation.navigate('HomeScreen')
                                    } catch (error) {
                                        console.log('errorerror', error);
                                        setAlert({ msg: error.message })
                                        Logger(error)

                                    }
                                }}
                                style={{
                                    paddingHorizontal: 16,
                                    paddingVertical: 16,
                                    borderRadius: 10,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    display: 'flex',
                                    borderWidth: 1,
                                    borderColor: colorTextDark

                                }}>
                                <CustomImage
                                    width={24}
                                    height={24}
                                    svg
                                    fill={colorTextDark}
                                    uri={rec.img}
                                />
                                <Text style={{ textAlign: 'center', fontSize: 16, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{rec.title}</Text>
                                <View style={{ width: 24, height: 24 }} />
                            </TouchableOpacity>
                        </View>)
                    }

                </View>


            </ScrollView>
        </SafeAreaView>
    );
};

export default Login;