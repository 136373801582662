import { View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { FreeDeliverySVG } from '../../constants/imgs';
import Image from "./customImg";
import { Text } from './custom';
import { useDispatch, useSelector } from 'react-redux';
import { SafeAreaView } from 'react-native';
import { CheckStoreClosed, GetOrderType } from '../../helpers/helpers';
export function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = [hours, minutes, remainingSeconds]
        .map((time) => String(time).padStart(2, '0'))
        .join(':');

    return formattedTime;
}

const ClosedBanner = () => {
    const { location, items } = useSelector(state => state.order);
    const { locale, strings } = useSelector(state => state.locale)
    const { allLocations } = useSelector(state => state.settings)

    const [state, setState] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (location?.type === 'delivery') {
            let type = GetOrderType(items)
            switch (type) {
                case 'mix': case 'sameDay':
                    let closedUntil = dispatch(CheckStoreClosed());
                    setState(closedUntil)
                    break;
                default:
                    setState(0)
                    break;
            }
        } else {
            setState(0)
        }
    }, [items, location, allLocations])
    useEffect(() => {
        let interval;
        if (state) {
            interval = setInterval(() => {
                setState(state - 1)
            }, 1000);
        }
        if (state === 0) {
            clearInterval(interval)
        }
        return () => {
            clearInterval(interval)
        }
    }, [state])
    if (!state) {
        return <View />

    }
    return (
        <SafeAreaView style={{ backgroundColor: '#E0262D30' }}>
            <View style={{
                paddingHorizontal: 16,
                paddingVertical: 12,
                // backgroundColor: 'rgba(255, 137, 0, 0.05)',
                borderColor: 'rgba(182,147,41)',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>

                <Image
                    fill='rgba(224, 38, 45, 1)'
                    svg
                    width={25}
                    height={25}
                    uri={FreeDeliverySVG}
                />
                <View style={{ marginHorizontal: 15 }}>
                    <Text style={{
                        color: '#000',
                        textAlign: 'left',
                        fontSize: 12,
                        fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular'
                    }}>{strings?.["Sorry we cannot accept FAST DELIVERY orders at the moment"]}</Text>

                    <Text style={{
                        color: '#000',
                        textAlign: 'left',
                        fontSize: 14,
                        fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold',
                    }}>{`${strings?.["You can place your order after"]}  ${formatTime(state)}`}</Text>

                </View>
            </View>
        </SafeAreaView>

    );
};

export default ClosedBanner;