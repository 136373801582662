import { useNavigation } from '@react-navigation/core';
import React, { useState } from 'react';
import { View, FlatList, TouchableOpacity, Modal, TextInput, Platform, Dimensions, ActivityIndicator } from 'react-native';
import { backgroundColor, colorLightGrey, colorTextDark, colorTextGrey, mainColor, redColor, secColor } from '../../constants/color';
import { CloseBtn, Star } from '../../constants/imgs';
import ProductCard from '../shared/productCard';
import CustomImage from '../shared/customImg'
import ActionBtn from '../shared/ActionBtn';
import { Text } from '../shared/custom'
import { connect, useSelector } from 'react-redux';
import { getDate } from '../../helpers/helpers';
import { firestore } from '../../constants/firebase/config'
const OrderRecord = ({ review, navigation, data }) => {
    let orderDate = getDate(data?.created)
    const { locale, strings } = useSelector((state) => state.locale);
    let colors = { new: '#DE8329', accepted: '#32C5FF', active: '#6DD400', completed: '#6DD400', canceled: '#DE2934', rejected: '#DE2934' }

    return (
        <View style={{ flex: 1, backgroundColor: backgroundColor, marginBottom: 16, padding: 16, width: 'auto', alignItems: 'flex-start', }}>
            <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View >
                    <Text style={{ fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', fontSize: 12, textAlign: 'left' }}>{strings.order + " " + data?.orderNumber} </Text>
                    <Text style={{ fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular', fontSize: 10, paddingTop: 3, textAlign: 'left' }}>{strings.placedOn + " " + orderDate.date} {orderDate.time} </Text>
                </View>
                <View style={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'flex-end' }}>
                    <Text style={{ fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', fontSize: 12, textAlign: 'left' }}>{strings.status} </Text>
                    <Text style={{ color: colors[data?.status], fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', fontSize: 12, paddingTop: 3 }}>{strings[data?.status]} </Text>
                </View>

            </View>
            <FlatList
                style={{ paddingTop: 16, width: Platform.OS === 'web' ? '100%' : 'auto' }}
                data={data?.items}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                renderItem={(i) =>
                    <View key={i.item} style={{ width: 180 }} >
                        <ProductCard basic data={i.item} key={i.item} index={i.item.id} />
                    </View>
                }
                keyExtractor={item => item.id}
            />
            <View style={{ width: '100%', paddingTop: 16, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                {
                    data.rated || ['new', 'pending', 'accepted', 'active'].includes(data?.status) ?
                        <View />
                        :
                        <TouchableOpacity style={{ padding: 8, backgroundColor: '#E0262D20', borderRadius: 6 }} onPress={() => review(data.id)}>
                            <Text style={{ color: redColor, fontSize: 10, fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular' }}>{strings.reviewOrder}</Text>
                        </TouchableOpacity>
                }

                <TouchableOpacity onPress={() => navigation.navigate('Status Page', { data })}>
                    <Text style={{ color: redColor, fontSize: 12, fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Medium' }}>{strings.viewDetails}</Text>
                </TouchableOpacity>
            </View>

        </View>
    )

}
const Stars = ({ count, update }) => {
    return (
        <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 8 }}>
            {
                [0, 1, 2, 3, 4].map(rec =>
                    <View key={rec}>
                        <TouchableOpacity onPressIn={() => update(rec)} key={rec} style={{ paddingRight: 8, zIndex: 999 }}>
                            <View pointerEvents='none'>
                                <CustomImage width={30} height={30} svg fill={count > rec ? redColor : colorTextGrey} uri={Star} />
                            </View>
                        </TouchableOpacity>
                    </View>

                )
            }

        </View>
    )
}
const ModalView = ({ show, dismiss }) => {
    const [rate, setRate] = useState({ overall: 3, delivery: 3, product: 3 });
    const [comment, setComment] = useState('');
    const { locale, strings } = useSelector((state) => state.locale);
    const [loading, setLoading] = useState(false)

    return (
        <Modal
            animationType='slide'
            presentationStyle='overFullScreen'
            transparent={true}
            visible={!!show}

        >
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: Dimensions.get('window').width > 900 ? '30%' : '100%', height: '100%', elevation: 4, shadowColor: '#000' }}>
                {loading && <View style={{
                    // backgroundColor: redColor,
                    padding: 16,
                    borderRadius: 10,
                    position: 'absolute',
                    zIndex: 10000000,
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    height: '100%',
                    width: '100%',
                }}>
                    <ActivityIndicator size="large" color={redColor} />
                </View>
                }
                <View style={{ backgroundColor: mainColor, width: '80%', height: '60%', minHeight: 470, borderRadius: 16, paddingHorizontal: 16 }}>
                    <Text style={{ textAlign: 'left', fontSize: 22, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', paddingTop: 24 }}>
                        {strings.reviewYourOrder}
                    </Text>
                    <TouchableOpacity onPressIn={() => dismiss()} style={{ position: 'absolute', padding: 16, right: 0, zIndex: 99 }}>
                        <CustomImage width={30} height={30} fill={redColor} svg uri={CloseBtn} />
                    </TouchableOpacity>
                    {
                        [{ id: 'overall', text: 'Overall order experience' }, { id: 'delivery', text: 'Delivery on time' }, { id: 'product', text: 'Rate products' }].map(rec => <View key={rec.id} style={{ paddingTop: 16 }}>
                            <Text style={{ textAlign: 'left', }}>{strings[rec.text]}</Text>
                            <Stars count={rate[rec.id]} update={(val) => {
                                rate[rec.id] = val + 1;
                                setRate({ ...rate })
                            }} />
                        </View>)
                    }
                    <View style={{ paddingTop: 16, paddingBottom: 16, }}>
                        <TextInput multiline
                            placeholder={strings.writeFeedback}
                            placeholderTextColor={colorTextDark}
                            style={{ textAlign: locale === 'en' ? 'left' : 'right', outline: 'none', backgroundColor: colorLightGrey, color: '#000', borderRadius: 8, padding: 8, height: 80 }}
                            numberOfLines={4}
                            onChangeText={text => setComment(text)} />
                    </View>

                    <View style={{ width: '100%', flex: 1, paddingVertical: 16, marginBottom: 16 }}>
                        <ActionBtn title={strings.submitReview} fromCart onPress={() => {
                            setLoading(true)
                            let batch = firestore().batch()
                            batch.update(firestore().collection('orders').doc(show), { rated: Date.now() });
                            batch.set(firestore().collection('reviews').doc(show), {
                                created: Date.now(),
                                order: show,
                                rate: rate,
                                comment: comment
                            });
                            batch.commit().then(() => {
                                setLoading(false)
                                dismiss()
                            })
                        }} />
                    </View>
                </View>
            </View>

        </Modal>
    )
}
const PreviousOrders = ({ navigation, orders }) => {
    const [show, setShow] = useState(false)
    const { strings } = useSelector((state) => state.locale);

    return (
        <View style={{ flex: 1, backgroundColor: mainColor, width: 'auto', alignItems: 'flex-start', }}>
            {Object.values(orders || {})?.length ? <FlatList
                style={{ width: '100%' }}
                contentContainerStyle={{ paddingBottom: 70 }}
                data={Object.values(orders || {})?.sort((a, b) => { return (b.created - a.created) })}
                showsVerticalScrollIndicator={false}
                renderItem={(i) =>
                    <OrderRecord data={i.item} navigation={navigation} review={(id) => setShow(id)} />
                }
                keyExtractor={item => item.id}
            /> :
                <View
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        width: '100%'
                    }}>
                    <Text style={{ fontSize: 18 }}>{strings.noOrders}</Text>
                </View>}
            {
                show && <ModalView show={show} dismiss={() => setShow(false)} />
            }

        </View>
    );
};
const mapStateToProps = ({ orders }) => {
    return { orders: orders || [] }
}
export default connect(mapStateToProps, {})(PreviousOrders);