import { createAction } from 'redux-action';
const SET_LOADING = 'SET_LOADING';
const SET_SERVING_AREAS = 'SET_SERVING_AREAS';
const SET_PICKUP_AREAS = 'SET_PICKUP_AREAS';

const SET_APP_PLACES = 'SET_APP_PLACES';

const SET_ALERT = 'SET_ALERT';
const SET_LOGIN = 'SET_LOGIN';

export const SetLoading = createAction(SET_LOADING, (data) => {
    return data
});

export const SetAlertMessage = createAction(SET_ALERT, (data) => {
    return data
});

export const SetServingAreas = createAction(SET_SERVING_AREAS, (data) => {
    return data
});
export const SetPickupAreas = createAction(SET_PICKUP_AREAS, (data) => {
    return data
});
export const SetAppPlaces = createAction(SET_APP_PLACES, (data) => {
    return data
});

export const SetLoginRequired = createAction(SET_LOGIN, (data) => {
    return data
});


const initial = {
    loading: false,
};

let ex = (state = initial, action) => {

    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: action.payload }

        case SET_SERVING_AREAS:
            return { ...state, ...action.payload }

        case SET_ALERT:
            return { ...state, alertObject: action.payload }

        case SET_LOGIN:
            return { ...state, loginRequired: action.payload }
        case SET_APP_PLACES:
            return { ...state, places: action.payload }
        case SET_PICKUP_AREAS:
            return { ...state, pickupLocations: action.payload }

        default:
            return state;
    }
};
export default ex