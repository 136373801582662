import { createAction } from 'redux-action';
export const GET_OFFERS = 'GET_OFFERS';
export const GetOffers = createAction(GET_OFFERS, (list) => {
    return list
});
const initial = {};

let ex = (state = initial, action) => {
    switch (action.type) {
        case GET_OFFERS:
            return action.payload
        default:
            return state;
    }
};
export default ex