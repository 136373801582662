import { createAction } from 'redux-action';
export const GET_PRDS = 'GET_PRDS';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_BANNER = 'GET_BANNER';
export const GET_BRANDS = 'GET_BRANDS';

export const GetPrds = createAction(GET_PRDS, (list) => {
    return list
});
export const GetCategories = createAction(GET_CATEGORIES, (list) => {
    return list
});
export const GetBanner = createAction(GET_BANNER, (list) => {
    return list
});
export const GetBrands = createAction(GET_BRANDS, (list) => {
    return list
});

const initial = { prds: [], categories: [] };

let ex = (state = initial, action) => {
    switch (action.type) {
        case GET_PRDS:
            return { ...state, prds: action.payload || [] }
        case GET_CATEGORIES:
            return { ...state, categories: action.payload || [] }
        case GET_BANNER:
            return { ...state, banner: action.payload || [] }
        case GET_BRANDS:
            return { ...state, brands: action.payload || [] }
        default:
            return state;
    }
};
export default ex