import moment from "moment";
import { getAreaOfPolygon, isPointInPolygon } from 'geolib';
import { firestore, auth } from '../constants/firebase/config'
export function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}
export function totalPriceForItem(orderedItem) {
    let price = orderedItem?.price || 0
    let selected = orderedItem?.selectedModifiers;
    if (!selected) {
        return price
    }
    Object.values(selected || {})?.map(value => {
        let optionPrice = Number(value.price)
        let count = Number(value.count) || 1
        price = (price * 1) + (optionPrice * count)
        return price
    })
    return price
}
export function GetOrderType(orderList) {
    if (Object.values(orderList || {})?.find(r => r.sameDay) && Object.values(orderList || {})?.find(r => !r.sameDay)) {
        return 'mix'
    }
    if (Object.values(orderList || {})?.find(r => !r.sameDay)) {
        return 'schaduled'
    }
    if (Object.values(orderList || {})?.find(r => r.sameDay)) {
        return 'sameDay'
    }
}
export function CompareVersions(version1, version2) {
    // split the versions into arrays of numbers
    const version1Parts = version1?.split('.')?.map(Number);
    const version2Parts = version2?.split('.')?.map(Number);

    // compare the parts from left to right
    for (let i = 0; i < Math.max(version1Parts.length, version2Parts.length); i++) {
        // if the current part is undefined, assume it is 0
        const part1 = version1Parts[i] || 0;
        const part2 = version2Parts[i] || 0;

        // if the parts are different, return 1 if the first is greater, -1 if the second is greater, or 0 if they are equal
        if (part1 !== part2) {
            return part1 > part2;
        }
    }

    // if all parts are equal, return 0
    return 0;
}
export function calcTotalCost(orderList, promo, deliveryFees = 0, freeDeliveryFees) {
    let cost = 0
    let deductedValue;
    let orderType = GetOrderType(orderList)

    Object.values(orderList || {})?.map(orderedItem => {
        let price = totalPriceForItem(orderedItem)
        return cost = cost + (price * orderedItem?.count)
    })

    let sub = (cost).toFixed(2)
    let subFreeDelivery = (cost).toFixed(2)

    if (promo) {

        let { appliedOn } = promo || {}
        let percent = (promo?.percent / 100)
        deductedValue = 0
        switch (appliedOn?.type) {
            case 'All':
                deductedValue = (cost * percent);
                break;
            case 'Brands':
                Object.values(orderList || {})?.map(orderedItem => {
                    let { brand, count } = orderedItem;
                    if (appliedOn?.items?.includes(brand)) {
                        deductedValue = deductedValue + (totalPriceForItem(orderedItem) * percent * count)
                    }
                    return false
                })
                break;
            case 'Products':
                Object.values(orderList || {})?.map(orderedItem => {
                    let { id, count } = orderedItem;
                    if (appliedOn?.items?.includes(id)) {
                        deductedValue = deductedValue + (totalPriceForItem(orderedItem) * percent * count)
                    }
                    return false
                })
                break;
            case 'Product Categories':
                Object.values(orderList || {})?.map(orderedItem => {
                    let { count } = orderedItem;
                    if (appliedOn.items?.some(ele => orderedItem['prds_category'][ele])) {
                        deductedValue = deductedValue + (totalPriceForItem(orderedItem) * percent * count)
                    }
                    return false
                })
                break;
            default: break;
        }
        deductedValue = (promo?.cap && deductedValue > promo.cap) ? promo?.cap : deductedValue
        cost = (cost - deductedValue);
        subFreeDelivery = (cost).toFixed(2)
    }
    let tax = (cost * 0.05).toFixed(2)
    let deliveryFeesAmount = (orderType === 'schaduled') ? ((deliveryFees && (subFreeDelivery < Number(freeDeliveryFees))) ? deliveryFees : 0) : deliveryFees
    // if (deliveryFees && ((subFreeDelivery) < Number(freeDeliveryFees))) {
    cost = cost + Number(deliveryFeesAmount)
    // }
    let post = {
        sub,
        tax,
        total: cost.toFixed(2),
        dummyTotal: cost,
        deliveryFees: deliveryFeesAmount,
        subFreeDelivery,
        freeDeliveryActive: (orderType === 'schaduled'),
        orderType
    }
    if (deductedValue) {
        post.deductedValue = deductedValue
        post.code = promo.code
        post.percent = promo?.percent
    }

    return (post)
}
export function checkDiscount(discount, item) {
    if (discount) {
        let { appliedOn } = discount || {};
        let percent = ((discount?.percent) / 100)
        let { type, items } = appliedOn || {}
        let { key } = item || {};
        let price = totalPriceForItem(item)

        switch (type) {
            case 'All':
                return { percent: discount?.percent, price: (price * percent) }
            case 'Brands':
                if (items?.includes(item?.brand)) {
                    return { percent: discount?.percent, price: (price * percent) }
                }
                break;
            case 'Products':
                if (items?.includes(key)) {
                    return { percent: discount?.percent, price: (price * percent) }
                }
                break;
            case 'Product Categories':
                if (items?.some(ele => item['prds_category'][ele])) {
                    return { percent: discount?.percent, price: (price * percent) }
                }
                break;
            default:
                return false;
        }
    }
    return false
}


export function getDate(ts) {
    let date = moment(ts).format('MMM DD yyyy').toString()
    let time = moment(ts).format('hh:mm A').toString()
    return ({ date, time })

}
export const OptimizeLocationToPolygons = (params) => async (dispatch, getState) => {
    const { settings: { allLocations } } = getState()
    let { locations, uid } = params || {};
    let updated;
    if (locations) {
        locations = await Promise.all(locations?.map(async rec => {
            if (!rec?.optimized) {
                let { geometry: { location } } = rec;
                let { cluster } = dispatch(CheckLocationIsServed(location))
                if (cluster) {
                    updated = true
                    rec.locationId = cluster
                    rec.optimized = true
                }
            }
            return rec
        }))
        if (updated) {
            try {
                await firestore().collection('users').doc(uid).update({
                    locations: locations,
                })
            } catch (error) {

            }

        }

    }
}


export function checkLocationsServed(servedAreas, center) {
    let existed = servedAreas?.filter(rec => {
        const bound = [
            {
                latitude: rec.viewport.north_East.lat,
                longitude: rec.viewport.north_East.lng,
            },
            {
                latitude: rec.viewport.south_west.lat,
                longitude: rec.viewport.north_East.lng,
            },
            {
                latitude: rec.viewport.south_west.lat,
                longitude: rec.viewport.south_west.lng,
            },
            {
                latitude: rec.viewport.north_East.lat,
                longitude: rec.viewport.south_west.lng,
            },
        ];
        const customerIsInWashersServeArea = isPointInPolygon(
            center,
            bound,
        );
        return customerIsInWashersServeArea ? rec : false
    })
    return existed?.length ? existed[0] : false
}

export const CheckLocationIsServed = (currenrLocation) => (dispatch, getState) => {
    let { settings: { allLocations: servingAreas, places } } = getState();
    let places_ids = (Object.keys(servingAreas || {}) || [])?.reduce((val, currentKey) => {
        let currentVal = servingAreas[currentKey];
        if (currentVal?.active) {
            let list = currentVal?.areas_list?.map(r => {
                return { cluster: currentKey, ...r }
            })
            val = val.concat(list?.filter(r => !!r) || [])
        }
        return val
    }, [])

    let polygons = places_ids?.map(r => {
        let placeData = places?.find(rec => rec?.id === r?.id);
        return { ...placeData, cluster: r.cluster }
    })
    let place = polygons.filter(r => {
        let existed;
        switch (r.geojson?.type) {
            case "MultiPolygon":
                existed = Object.keys(r?.geojson?.coordinates || {}).reduce((val, current) => {
                    let existed = isPointInPolygon(currenrLocation, (r?.geojson?.coordinates?.[current] || []))
                    if (existed) {
                        val = true
                    }
                    return val
                }, false)
                return existed
            default:
                let polygon = r.geojson?.coordinates || [];
                existed = isPointInPolygon(currenrLocation, polygon)
                return existed
        }
    })
    let newPlace = place.sort((a, b) => { getAreaOfPolygon(a?.geojson?.coordinates) - getAreaOfPolygon(b?.geojson?.coordinates) })
    return { place_id: newPlace?.[0]?.place_id, cluster: newPlace?.[0]?.cluster }
}
export async function getResturantLocations() {
    let res = await firestore().collection('pickup_areas').get()
    let PickupLocations = {}
    res.docs.map(rec => {
        let data = rec.data()
        let { active } = data
        if (active) {
            PickupLocations[rec.id] = data
        }
        return false
    })
    return { PickupLocations }
}

export async function getLocations() {
    let res = await firestore().collection('delivering_areas').where('active', '==', true).get()
    let supportedAreas = []
    let allLocations = {}
    res.docs.map(rec => {
        let data = rec.data()
        let { areas, active } = data
        if (active) {
            // if (true) {
            Object.keys(areas || {}).map(areaKey => {
                let areaObject = areas[areaKey]
                let { list } = areaObject
                let optimizedList = list.map(location => {
                    return ({ ...location, id: rec.id, key: rec.id })
                })
                supportedAreas = supportedAreas.concat(optimizedList)
                return false
            })
        }
        allLocations[rec.id] = data
        return false
    })
    return { supportedAreas, allLocations }
}
export async function applyPromo(code) {
    if (code) {

        let snap = await firestore().collection('offers').where('code', '==', code.toLowerCase()).limit(1).get()
        if (snap.empty) {
            return { error: 'Please enter valid promocode' }
        }
        let results = snap.docs.map(rec => {
            let data = rec.data()
            let { active, endDate } = data
            if (active) {
                if (!endDate || endDate > Date.now()) {
                    return { ...data, id: rec.id }
                } else {
                    return { error: 'this promocode is expired' }
                }
            } else {
                return { error: 'this promocode is expired' }
            }
        })
        return results[0]
    }

}


export function translateSelectedSlot(dateObj) {
    const today = moment().startOf('day');

    if (!dateObj) {
        return false
    }
    let { date, slot } = dateObj
    let dateString;

    const inputDate = moment(date?.dateTimestamp).startOf('day');

    if (inputDate.isSame(today, 'day')) {
        dateString = 'Today';
    } else if (inputDate.isSame(today.clone().add(1, 'day'), 'day')) {
        dateString = 'Tomorrow';
    } else {
        dateString = inputDate.format('DD/MM')
    }

    let timeSting = [moment(slot?.start)?.format('hh:mm A'), moment(slot?.end)?.format('hh:mm A')].join(' - ')
    return `${dateString} at ${timeSting}`


}

export const CheckStoreClosed = () => (dispatch, getState) => {
    let { order: { location }, settings: { allLocations } } = getState();
    let now = moment()
    let weekDays = [{ Key: 'Sunday', Label: 'Sun' },
    { Key: 'Monday', Label: 'Mon' },
    { Key: 'Tuesday', Label: 'Tue' },
    { Key: 'Wednesday', Label: 'Wed' },
    { Key: 'Thursday', Label: 'Thu' },
    { Key: 'Friday', Label: 'Fri' },
    { Key: 'Saturday', Label: 'Sat' }]


    let timings = allLocations?.[location?.locationId]?.deliveryTimings || location?.params?.deliveryTimings || location?.timings;
    let breakLoop = false;
    let closedUntil = 0
    let currentWeekDay = now.day();
    let initialDay = 5
    while (!breakLoop && closedUntil === 0) {
        let { timings: daySlots, isOpen } = timings?.[weekDays[currentWeekDay].Key] || { daySlots: [] };

        closedUntil = daySlots?.reduce((val, currentVal) => {
            let startTime = moment(`${moment().add(initialDay - 5, 'day').format('yyyy-MM-DD')}T${moment(currentVal?.startTime).format('HH:mm:ss')}`);
            let endTime = moment(`${moment().add(initialDay - 5, 'day').format('yyyy-MM-DD')}T${moment(currentVal?.endTime).format('HH:mm:ss')}`);
            if (endTime.isBefore(startTime)) {
                endTime = endTime.add(1, 'day');
            }
            // let nowTime = moment(`1955-01-05T${now.format('HH:mm:ss')}`);
            if (isOpen) {
                if (now.isBetween(startTime, endTime)) {
                    breakLoop = true
                }
                if (now.isBefore(startTime) && moment(val).isBefore(startTime)) {
                    val = startTime.valueOf();
                }
            }

            return val;
        }, 0);
        initialDay = initialDay + 1
        if (initialDay === 10) {
            breakLoop = true
        }
        currentWeekDay = (currentWeekDay + 1) % weekDays.length; // Increment and wrap around the array
    }

    if (closedUntil) {
        const duration = moment(closedUntil).diff(moment()); // Difference in milliseconds
        const durationInMinutes = Math.floor(moment.duration(duration).asSeconds());
        return durationInMinutes
    }
    return 0
}

export function EnableAdd(item) {

    let allowAdd = item?.modifiers?.map(rec => {
        let { key, min_count } = rec || {}
        if (!min_count || Number(min_count) === 0) {
            return true
        }
        let checked = Object.values(item?.selectedModifiers || {})?.filter(r => r.parent === key)
        let count = checked?.reduce((a, b) => a = a + (b.count || 1), 0)

        if (Number(count) >= Number(min_count)) {
            return true
        }
        return false
    })
    if (allowAdd?.includes(false)) {
        return false
    } else {
        return true
    }

}



export const verifyLocation = (user, order) => (dispatch, getState) => {
    let { locale: { strings }, settings: { pickupLocations, allLocations } } = getState()
    if (user?.locations?.length && Object.keys(order?.items || {})?.length) {
        if (order?.location) {
            if (order?.location?.type === 'pickup') {
                if (pickupLocations?.[order.location?.key]) {
                    return ({ type: 'location', location: { ...order?.location, ...pickupLocations } })
                } else {
                    return ({ type: 'alert', msg: strings?.['Sorry,This branch is not working at the moment!'], actionTitle: 'Change pickup spot', action: 'toggleLocations' })
                }
            } else {
                let { geometry } = order?.location || {};
                let { place_id, cluster } = dispatch(CheckLocationIsServed(geometry?.location))
                if (!place_id) {
                    return ({ type: 'alert', msg: strings?.['Sorry, Currently we are not serving this area!'], actionTitle: 'Change address', action: 'toggleLocations' })
                } else {
                    return ({
                        type: 'location', location: { ...order?.location, locationId: cluster, params: allLocations?.[cluster] || {} }
                    })
                }
            }
        } else {
            return ({ type: 'alert', msg: strings?.['Please select delivery address'], actionTitle: 'Select address', action: 'toggleLocations' })

        }
        return ({ type: 'pass' })
    } else {
        return ({ type: 'alert', msg: strings?.['completeProfileMsg'], actionTitle: "completeProfile", action: 'navigate' })
    }
}


