import React, { useState } from "react";
import { Text, TouchableOpacity, View, Image, Linking, Dimensions, Platform } from "react-native";
import Swiper from "react-native-web-swiper";
import { redColor } from '../../constants/color'
import { useNavigation } from '@react-navigation/core';
import { useSelector } from "react-redux";
import { Skeleton } from "native-base";
const width = Dimensions.get('screen').width
const SomeSlide = ({ data, helpers }) => {
    const navigation = useNavigation()
    const { locale } = useSelector((state) => state.locale);
    const [loading, setLoading] = useState(true)
    let image = `https://cdn.taybagourmet.com/banners/${data.id}/${locale !== 'en' ? 'logo_ar' : 'logo'}`;
    if (data?.[locale !== 'en' ? 'img_ar' : 'img']?.[0]?.includes('https://cdn.taybagourmet.com')){
        image  = data?.[locale !== 'en' ? 'img_ar' : 'img']?.[0]
    };
    return (
        <>
            <TouchableOpacity onPress={() => {
                switch (data?.type) {
                    case 'url':
                        Linking.openURL(data?.url).catch(err => console.log(err))
                        break;
                    case 'brand':
                        navigation.navigate('Results', { brand: helpers?.brands?.find(rec => rec.id === data?.selected), filterType: 'brand' })
                        break;
                    case 'product':
                        navigation.navigate('Product', { id: data?.selected, data: helpers?.prds?.find(rec => rec.id === data?.selected) })
                        break;
                    case 'prdCat':
                        navigation.navigate('Results', { category: data?.selected, filterType: 'category' })
                        break;
                    default:
                        break;
                }
            }} key={data.id} style={{ paddingHorizontal: 16 }} >
                <Image
                    style={{
                        width: '100%',
                        aspectRatio: 1.78,
                        borderRadius: 8,
                        resizeMode: "cover"

                    }}
                    onLoadEnd={() => setLoading(false)}

                    source={{ uri: image, cache: 'force-cache' }}
                />

                {/* <View style={{ paddingTop: 20 }} /> */}

            </TouchableOpacity>
            {

                loading && <View style={{ position: 'absolute', width: Platform.OS === 'web' ? '100%' : width, aspectRatio: 1.78, paddingHorizontal: 16 }}>
                    <Skeleton
                        style={{ borderRadius: 8, height: '100%', aspectRatio: 1.78 }}
                    />
                </View>


            }
        </>
    )

}
const Carosel = ({ data, helpers }) => {
    return (
        <View style={{ flex: 1 }}>
            <View style={{ flex: 1 }}>
                <Swiper
                    loop
                    timeout={4}
                    springConfig={{ speed: 11 }}
                    // key={data?.length}
                    minDistanceForAction={0.1}
                    controlsProps={{
                        dotsTouchable: true,
                        nextTitleStyle: { display: 'none' },
                        dotsPos: 'bottom',
                        PrevComponent: ({ onPress }) => (
                            <TouchableOpacity onPress={onPress}>
                            </TouchableOpacity>
                        ),
                        DotComponent: ({ index, isActive, onPress }) => <View key={index} onPress={onPress} style={{ bottom: -13, marginHorizontal: 2, backgroundColor: isActive ? redColor : '#cacaca', width: 11, height: 2 }}></View>
                    }}
                >
                    {
                        data?.map(item => <SomeSlide key={item.id} data={item} helpers={helpers} />)
                    }
                </Swiper>
            </View>
        </View>
    );
};

export default Carosel;
