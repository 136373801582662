import { auth } from '../constants/firebase/config.web'

export async function onGoogleButtonPress() {
    const provider = new auth.GoogleAuthProvider();
    provider.setCustomParameters({
        'display': 'popup'
      });
    return auth().signInWithPopup(provider)
}
export async function onFacebookButtonPress() {
    const provider = new auth.FacebookAuthProvider();
    provider.setCustomParameters({
        'display': 'popup'
      });
    return auth().signInWithPopup(provider)

}
export async function onAppleButtonPress() {
}
export function logout(){
  auth().signOut()
  
}


export function Logger(error) {
  
}

export const requestLocationPermission = async () => {
    return true
}