import { I18nManager, Platform } from 'react-native';
import { strings } from '../translations/index';
import RNRestart from 'react-native-restart';
import AsyncStorageStatic from '@react-native-async-storage/async-storage';
import { createAction } from 'redux-action';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export const localeSetLanguage = createAction(SET_LANGUAGE, async (lang) => {
  let language = await AsyncStorageStatic.getItem('language')

  if (lang === 'en') {
    await I18nManager.allowRTL(false);
    await I18nManager.forceRTL(false);

    if (Platform.OS === 'web' && language !== lang) {
      window.location.reload()
    }
    strings.setLanguage('en');
    AsyncStorageStatic.setItem('language', 'en');
  } else {
    await I18nManager.allowRTL(true);
    await I18nManager.forceRTL(true);
    if (Platform.OS === 'web' && language !== lang) {
      window.location.reload()
    }
    strings.setLanguage('ae');
    AsyncStorageStatic.setItem('language', 'ae');
  }

  return lang
});


const locales = [
  { locale: 'en', label: 'English' },
  { locale: 'ae', label: 'Arabic' },
];


const INITIAL_STATE = {
  locales: locales,
  isRTL: I18nManager.getConstants().isRTL,
  locale: I18nManager.getConstants().isRTL ? 'ae' : 'en',
  strings: strings,
};

export default (state = INITIAL_STATE, action) => {
  const { type } = action;
  switch (type) {
    case SET_LANGUAGE:
      return { ...state, locale: I18nManager.getConstants().isRTL ? 'ae' : 'en', };
    default:
      return { ...state };
  }
};

