import React from 'react';
import { Dimensions, Platform, TouchableOpacity, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';
import { redColor } from '../../constants/color';
import { Text } from '../shared/custom'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

const ActionBtn = ({ title, price, onPress, disabled, count, fromCart, desktop }) => {
    const { locale, strings } = useSelector((state) => state.locale);
    const insets = useSafeAreaInsets();


    const Btn = () => {
        return (
            <TouchableOpacity
                onPress={() => { disabled ? {} : onPress() }}
                style={{
                    height: 50,
                    paddingHorizontal: 16,
                    // paddingVertical: 16,
                    backgroundColor: price && count ? '#28BE34' : redColor,
                    bottom: Platform.OS === 'web' || insets.bottom === 0 ? (fromCart ? 0 : 16) : undefined,
                    width: '100%',
                    borderRadius: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                <Text style={{
                    color: '#fff',
                    textAlign: 'center', fontSize: 16, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', width: price ? 'auto' : '100%'
                }}>{title}</Text>
                {
                    price && count ? <Text style={{
                        color: '#fff',
                        textAlign: 'center', fontSize: 16, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold'
                    }}>{((price * count).toFixed(2)) || '0 AED'} {strings.AED}</Text>:<></>
                }
            </TouchableOpacity>
        )

    }
    if (fromCart) {
        return (
            <View style={
                Platform.OS === 'android' || Platform.OS === 'web' ?
                    {
                        bottom: 10,
                        position: 'relative',
                        height: 50,
                        paddingHorizontal: 16,
                        width: '100%',
                        opacity: disabled ? 0.5 : 1
                    } :
                    {
                        bottom: 10,
                        position: fromCart ? 'relative' : 'absolute',
                        paddingHorizontal: 16,
                        width: '100%',
                        opacity: disabled ? 0.5 : 1
                    }}>
                <Btn />

            </View>
        )
    }
    return (
        <SafeAreaView style={
            Platform.OS === 'android' || Platform.OS === 'web' ?
                {
                    bottom: 20,
                    position: Platform.OS === 'android' ? 'absolute' : 'fixed',
                    height: 50,
                    paddingHorizontal: 16,
                    width: '100%',
                    // width: Dimensions.get('window').width > 900 && desktop ? (price ? "100%" : '30%') : '100%',
                    opacity: disabled ? 0.5 : 1
                } :
                {
                    bottom: 0,
                    position: 'absolute',
                    paddingHorizontal: 16,
                    width: '100%',
                    opacity: disabled ? 0.5 : 1
                }}>
            <Btn />
        </SafeAreaView>

    );
};

export default ActionBtn;