import React from 'react';
import { View, TouchableOpacity } from 'react-native'
import { mainColor, redColor, secColor } from '../../constants/color';
import { Globe } from '../../constants/imgs';
import Image from '../shared/customImg'
import { Text } from '../shared/custom'
import { localeSetLanguage } from '../../reducers/locale';
import { connect, useSelector } from 'react-redux';

const LanguageCard = ({ data, localeSetLanguage }) => {
    const { locale } = useSelector((state) => state.locale);

    return (
        <TouchableOpacity style={{ marginVertical: 8 }} onPress={() => localeSetLanguage(data.lang)}>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingHorizontal: 16,
                backgroundColor: mainColor,
                paddingVertical: 24,
                borderRadius: 8,
                borderColor: '#2B2730'
            }}>
                <Image
                    svg
                    width={18}
                    height={18}
                    uri={Globe}
                    fill={redColor}
                />
                <Text style={{ paddingHorizontal: 8, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{data?.title}</Text>
            </View>
        </TouchableOpacity>

    );
};
const mapStateToProps = ({ locale }) => {
    return {
        locale
    }
}
export default connect(mapStateToProps, { localeSetLanguage })(LanguageCard);