export default {
  orderFromTayba: 'Order from Tayba',
  shopByCategory: 'Shop by category',
  search: 'Search',
  showAll: 'Show All →',
  shopByBrand: 'Shop by brand',
  deliveringTo: 'Delivering to:',
  Delivery: 'Delivery',
  Pickup: 'Pickup',
  newArrival: 'New Arrival',
  Tayba: 'Tayba',
  AED: 'AED',
  deliveringTo: 'Delivering to',
  readyToEat: 'Ready to Eat items',
  fastDelivery: 'Fast Delivery',
  estDelivery: 'Est. Delivery:',
  "Scheduled Delivery": 'Scheduled Delivery',
  scheduleDelivery: 'Schedule delivery',
  paymentMethod: 'Payment Method',
  chooseDateTime: 'Choose date & time',
  'Cash On Delivery': 'Cash On Delivery',
  'Card On Delivery (POS Machine)': 'Card On Delivery (POS Machine)',
  'Cash On Pickup': 'Cash On Pickup',
  'Card On Pickup (POS Machine)': 'Card On Pickup (POS Machine)',
  enterPromocode: 'Enter promocode',
  applyPromo: "Apply Promo",
  subTotal: 'Sub total',
  tax: 'Tax (0.5%)',
  discount: 'Discount',
  total: 'Total',
  completeOrder: 'Complete order',
  change: 'Change',
  searchPlace: 'Search for place',
  confirmLocation: 'Confirm location',
  yourName: 'Your name',
  yourNumber: 'Your number',
  yourEmail: 'Your email',
  area: 'Area',
  street: 'Street',
  buildingName: 'Building name',
  floor: 'Floor',
  flat: 'Flat',
  Home: 'Home',
  Work: 'Work',
  Other: 'Other',
  saveAddress: 'Save address',
  generalInfo: 'General Info',
  locations: 'Locations',
  favourites: 'Favourites',
  languages: 'Languages',
  orders: 'Orders',
  addLocation: 'Add Location',
  results: 'Results',
  profile: 'Profile',
  favouritesList: 'Favourites list',
  previousOrders: 'Previous orders',
  myAddresses: 'My Addresses',
  getInTouch: 'Get in touch',
  logOut: 'Log out',
  Other: 'Other',
  logoutMsg: 'you want to logout from the Tayba',
  logoutTitle: 'Log out? ',
  cancel: 'Cancel',
  general: 'General',
  settings: 'Settings',
  "Scheduled Delivery": 'Scheduled Delivery',
  otherItems: 'Other items',
  yourOrder: 'Your Order is',
  statusPage: 'Status Page',
  chat: 'Chat',
  cart: 'Cart',
  warning: 'Warning',
  cancelOrder: 'Cancel order',
  areYouSure: 'Are you sure?',
  quantity: 'Quantity',
  readyToEat: 'Ready to eat',
  allPricesIncludeVAT: '(inc. VAT)',
  choose: 'Choose',
  chooseAtLeaset: 'Choose at lease',
  chooseUpto: 'Choose upto',
  ingredients: 'Ingredients from our market',
  addToCart: 'Add to cart',
  required: 'Required',
  alert: 'Alert',
  completeProfile: 'Complete Profile',
  completeProfileMsg: 'Please complete your profile before ordering',
  login: 'Login',
  loginMsg: 'Login to use this feature',
  removeItem: 'Delete Item',
  removeItemMsg: 'Are you sure you want to delete this item?',
  facebookSignin: 'Sign in with Facebook',
  googleSignin: 'Sign in with Google',
  appleSignin: 'Sign in with Apple',
  enterMobileNumber: 'Enter your mobile number to continue',
  phoneNumber: 'Phone Number',
  continue: 'Continue',
  enterValidNumber: 'Please Enter valid phone number',
  orVia: 'Or via',
  off: 'OFF',
  home: 'Home',
  selectLocation: 'Select Location',
  verifyPhoneNumber: 'Verify Number',
  verifyNumberMsg: 'The mobile number is not verified. Please verify By sending OTP to your number',
  sendOTP: ' Send OTP',
  noOrders: 'No orders yet',
  noFavourites: 'No favourites yet',
  noLocations: 'No locations added',
  noResults: 'No results',
  dismiss: 'Dismiss',
  minOrderIs: 'Minimum order is',
  toThisArea: 'to this area',
  changeEmail: 'Change email',
  resetPassword: 'Reset password',
  name: 'Name',
  gender: 'Gender',
  DOB: 'Birthday',
  saveInfo: 'Save information',
  order: 'Order#',
  placedOn: 'Placed on',
  status: 'Status',
  reviewOrder: 'Review This order',
  viewDetails: 'View details →',
  reviewYourOrder: 'Review your order',
  'Overall order experience': 'Overall order experience',
  'Delivery on time': 'Delivery on time',
  'Rate products': 'Rate products',
  writeFeedback: 'Write your feedback',
  submitReview: 'Submit Review',
  Male: 'Male',
  Female: 'Female',
  addItemsMsg: 'Kindly add items to your cart first!',
  enterVerification: 'Please enter verification code sent',
  to: 'to',
  DontReceive: 'Don’t receive a code!',
  resendIn: 'Resend in',
  resend: 'Resend',
  upto: 'Up to',
  new: 'Pending',
  accepted: 'Confirmed',
  active: 'Active',
  completed: 'Completed',
  canceled: 'Canceled',
  rejected: 'Rejected',
  changeNumber: 'Change Number',
  confirm: 'Confirm',
  pickupFrom: 'Pickup from',
  sorry: 'Sorry!',
  notServingArea: 'We are not serving the picked area at this time',
  addNewAddress: 'Add new Address +',
  addressDetails: 'Address Details',
  deleteLocation: 'Are you sure you want to delete this location?',
  closedMsg: 'Pickup option from Tayba Gourmet is not available outside working hours',
  addedToCart: 'Added to cart',
  continueShopping: 'Continue Shopping',
  viewCart: 'View Cart',
  priceOnSelection: 'Price on selection',
  "Select Date": 'Select Date',
  "Sorry,This branch is not working at the moment!": 'Sorry,This branch is not working at the moment!',
  "Sorry, Currently we are not serving this area!": 'Sorry, Currently we are not serving this area!',
  "Change pickup spot": 'Change pickup spot',
  "Change address": 'Change address',
  "Select address": 'Select address',
  "Please select delivery address": 'Please select delivery address',
  existedNumber: 'This phone number is already in use',
  needFreeShipping: 'Still Need Free Shipping?',
  add: 'Add',
  forFreeShipping: 'AED more for free shipping',
  free: 'Free',
  "Items": 'Items',
  "Order Confirmation": 'Order Confirmation',
  "Please confirm your intent to proceed with placing this order.": 'Please confirm your intent to proceed with placing this order.',
  "Place Order": 'Place Order',
  "New Version": "New version of Tayba App is now available. Please update to the latest version now.",
  "Update":'Update',
  "No Slots available for selected date":'No Slots available for selected date',
  "Sorry we cannot accept FAST DELIVERY orders at the moment":'Sorry we cannot accept FAST DELIVERY orders at the moment',
  "You can place your order after":'You can place your order after'





};


