import React, { useEffect, useRef, useState } from 'react';
import { SafeAreaView, TextInput, View, TouchableOpacity, Keyboard, Platform } from 'react-native'
import CustomImage from '../shared/customImg'
import Logo from '../../assets/logoTayba.png'
import { Text } from '../shared/custom';
import { backgroundColor, colorTextDark, redColor, secColor } from '../../constants/color';
import { auth } from '../../constants/firebase/config'
import { useSelector } from 'react-redux';


const Verify = ({ route, navigation }) => {
    const { params } = route;
    const [confirmation, setConfirmation] = useState(params?.confirmation);
    const linkMobile = params?.linkMobile
    const updateMobile = params?.updateMobile

    const degi1 = useRef(null);
    const degi2 = useRef(null);
    const degi3 = useRef(null);
    const degi4 = useRef(null);
    const degi5 = useRef(null);
    const degi6 = useRef(null);
    const [pin, setPin] = useState([]);

    const { strings } = useSelector((state) => state.locale);
    const [timeout, setTime] = useState(60);



    async function verifyPhoneNumber(phoneNumber) {
        let newConfirmation;
        if (Platform.OS === 'web') {
            newConfirmation = await auth().signInWithPhoneNumber('+' + phoneNumber, window.recaptchaVerifier).catch(error => {
                return false
            });
        } else {
            newConfirmation = await auth().verifyPhoneNumber(phoneNumber).catch(error => {
                return false
            })
        }
        return setConfirmation(newConfirmation)
    }
    useEffect(() => {
        let timee = setTimeout(() => {
            setTime(timeout - 1);
        }, 1000);
        if (timeout === 0) {
            clearTimeout(timee)
        }
    }, [timeout])


    async function confirmCode(code) {
        if (linkMobile) {
            try {
                const credential = auth.PhoneAuthProvider.credential(confirmation.verificationId, code);
                if (updateMobile) {
                    await auth().currentUser.updatePhoneNumber(credential);
                    navigation.goBack()
                } else {
                    await auth().currentUser.linkWithCredential(credential);
                    navigation.goBack()
                }


            } catch (error) {
                if (error.code == 'auth/invalid-verification-code') {
                    console.log('Invalid code.', error);
                } else {
                    console.log('Account linking error', error);
                }
            }
        } else {
            try {
                await confirmation.confirm(code);
                navigation.navigate('HomeScreen')
            } catch (error) {
                console.log('Invalid code.', error);
            }
        }

    }
    return (
        <View style={{ paddingHorizontal: 16, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', height: '80%' }}>
            <SafeAreaView />
            <CustomImage uri={Logo} png width={'60%'} height={'35%'} />

            <Text>{strings.enterVerification}</Text>
            <Text>{strings.to + " " + params?.phoneNumber}</Text>

            <View style={{ paddingTop: 24, paddingBottom: 16, width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                {
                    [1, 2, 3, 4, 5, 6].map(rec => <TextInput
                        key={rec}
                        ref={[degi1, degi2, degi3, degi4, degi5, degi6][rec - 1]}
                        keyboardType='number-pad'
                        maxLength={1}
                        value={pin?.length ? pin[rec - 1] : ''}
                        onChangeText={(text) => {
                            if (text) {
                                let newPn = [...pin, text.charAt(text?.length - 1)];
                                setPin(newPn);
                                if (rec === 6) {
                                    confirmCode(newPn.join(''))
                                    return Keyboard.dismiss()
                                }
                                [degi1, degi2, degi3, degi4, degi5, degi6, degi6][rec].current.focus();
                            } else {
                                let newPn = pin.slice(0, rec - 1);
                                setPin(newPn);
                                [degi1, degi1, degi2, degi3, degi4, degi5, degi6][rec - 1].current.focus();
                            }
                        }}
                        style={{
                            width: '15%',
                            backgroundColor: backgroundColor,
                            height: 50,
                            borderRadius: 10,
                            color: colorTextDark,
                            paddingHorizontal: 16,
                            fontSize: 18,
                            textAlign: 'center'
                        }} />)
                }

            </View>
            <View style={{ paddingTop: 32, justifyContent: 'center', alignItems: 'center' }}>
                <Text>{strings.DontReceive}</Text>
                <TouchableOpacity disabled={timeout !== 0} onPress={async () => {
                    await verifyPhoneNumber(params?.phoneNumber)
                    setTime(60)
                }}>
                    {
                        timeout !== 0 ? <Text style={{ textAlign: 'center', paddingTop: 16 }}>{strings.resendIn} {timeout}</Text>
                            : <Text style={{ textAlign: 'center', paddingTop: 16, color: redColor }}>{strings.resend}</Text>

                    }
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default Verify;