import React, { useEffect, useState } from "react";
import { Platform, View, I18nManager, Dimensions, Linking } from "react-native";
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';
import { NavigationContainer, DarkTheme } from '@react-navigation/native'
// import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Home from './component/home'
import Search from "./component/search";
import { backgroundColor, mainColor, redColor } from "./constants/color";
import CustomHeader from "./component/shared/CustomHeader";
import { HomeSVG, Recent, Profile, SearchSVG, Basket } from "./constants/imgs";
import ProfilePage from './component/profile'
import Image from './component/shared/customImg'
import ProducPage from "./component/ProductPage";
import UserInfo from './component/profile/Info'
import Locations from "./component/profile/Locations";
import Favourits from "./component/profile/Favourits";
import Languages from "./component/profile/Languages";
import PreviousOrders from "./component/profile/PreviousOrders";
import AddLocation from "./component/Locations";
import LocationDetails from "./component/Locations/locationDetails";
import Cart from "./component/cart";
import StatusPage from "./component/statusPage";
import ResultsView from "./component/ResultsView";
import { firestore, auth, messaging } from "./constants/firebase/config";
import { useDispatch, useSelector } from "react-redux";
import { GetBanner, GetBrands, GetCategories, GetPrds } from "./reducers/prds";
import { FlushOrder } from "./reducers/order";
import { GetOffers } from './reducers/offers'
import Login from "./component/login";
import Verify from "./component/login/verify";
import { FlushUser, GetUser } from './reducers/user'
import { GetActiveOrders } from './reducers/orders'
import Chat from "./component/profile/chat/chat";
import { localeSetLanguage } from "./reducers/locale";
import AsyncStorage from "@react-native-async-storage/async-storage";
import SplashScreen from 'react-native-splash-screen';
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import ArabicEnglishScreen from "./component/ArabicEnglishScreen";
import { CheckStoreClosed, CompareVersions, OptimizeLocationToPolygons } from "./helpers/helpers";
import DeviceInfo from 'react-native-device-info';
import { SetAlertMessage, SetAppPlaces, SetPickupAreas, SetServingAreas } from "./reducers/settings";


const Tab = createBottomTabNavigator();

async function requestUserPermission() {
    let token = '';

    if (Platform.OS === 'ios') {
        const authorizationStatus = await messaging().requestPermission();
        if (authorizationStatus) {
            token = await messaging().getToken();
        }
    } else {
        try {
            token = await messaging().getToken();

        } catch (err) {

        }
    }
    return token
}
const MyTheme = {
    ...DarkTheme,
    colors: {
        primary: redColor,
        background: mainColor,
        card: mainColor,
        text: '#fff',
        border: backgroundColor,
        notification: mainColor,
    },
};
const HomeStack = createStackNavigator();


function HomeStackScreen({ user }) {
    const { strings } = useSelector((state) => state.locale);

    const [initialScreen, setInitialScreen] = useState(null);
    const dispatch = useDispatch();
    const [hideSplashScreen, setHideSplashScreen] = useState(false);
    useEffect(() => {
        (async () => {
            let language = await AsyncStorage.getItem('language');
            if (!language) {
                setInitialScreen('ArabicEnglishScreen');
            } else {
                setInitialScreen('HomeScreen');
            }
        })()

    }, [dispatch])



    useEffect(() => {
        if (initialScreen) {
            if (Platform.OS === 'android' || Platform.OS === 'ios') {
                setTimeout(function () {
                    SplashScreen.hide();
                }, 3000);
            }
            setHideSplashScreen(true)
        }
    }, [initialScreen]);

    if (hideSplashScreen) {

        return (
            <HomeStack.Navigator
                screenOptions={{
                    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS

                }}
                initialRouteName={initialScreen}
            >


                <HomeStack.Screen name="Login" component={Login} options={{
                    header: () => null,
                }} />
                <HomeStack.Screen name="Verify" component={Verify} options={{
                    header: () => null,
                }} />

                <HomeStack.Screen name="HomeScreen" component={TapNavigator} options={{
                    header: () => null,
                }} />
                <HomeStack.Screen name="ArabicEnglishScreen" component={ArabicEnglishScreen} options={{
                    header: () => null,
                }} />
                <HomeStack.Group screenOptions={{ presentation: 'modal', cardStyleInterpolator: CardStyleInterpolators.forModalPresentationIOS }}>
                    <HomeStack.Screen name="Product" component={ProducPage} options={{
                        header: () => null,
                        // animation: 'slide_from_bottom',

                    }} />
                </HomeStack.Group>

                <HomeStack.Screen name="UserInfo" component={UserInfo} options={{
                    header: () => <CustomHeader title={strings.generalInfo} basic />,

                }} />
                <HomeStack.Screen name="UserLocation" component={Locations} options={{
                    header: () => <CustomHeader title={strings.locations} basic />,

                }} />
                <HomeStack.Screen name="UserFavourits" component={Favourits} options={{
                    header: () => <CustomHeader title={strings.favourites} basic />,

                }} />
                <HomeStack.Screen name="Languages" component={Languages} options={{
                    header: () => <CustomHeader title={strings.languages} basic />,

                }} />
                <HomeStack.Screen name="PreviousOrders" component={PreviousOrders} options={{
                    header: () => <CustomHeader title={strings.orders} basic />,

                }} />
                <HomeStack.Screen name="Choose your location" component={AddLocation} options={{
                    header: () => <CustomHeader title={strings.addLocation} basic />,


                }} />
                <HomeStack.Screen name="Address Details" component={LocationDetails}
                    options={{
                        header: () => <CustomHeader title={strings.addressDetails} basic />,
                    }} />
                <HomeStack.Screen name="Cart" component={Cart} options={{
                    header: () => <CustomHeader title={strings.cart} basic />,


                }} />
                <HomeStack.Screen name="Status Page" component={StatusPage} options={{
                    header: () => <CustomHeader title={strings.statusPage} basic />,

                }} />
                <HomeStack.Screen name="Chat" component={Chat} options={{
                    header: () => <CustomHeader title={strings.chat} basic />,

                }} />

                <HomeStack.Screen name="Results" component={ResultsView} options={{
                    // header: () => <CustomHeader title={strings.results} />,
                    header: () => null,


                }} />


            </HomeStack.Navigator>
        );
    }
    return null;

}
function TapNavigator() {
    const { strings } = useSelector((state) => state.locale);
    const insets = useSafeAreaInsets();
    return (<Tab.Navigator

        screenOptions={({ route }) => ({
            tabBarStyle: {
                backgroundColor: 'transparent',
                minHeight: 60,
                position: Platform.OS === 'web' ? 'fixed' : 'absolute',
                width: '100%',
                border: 'none'
            },
            tabBarItemStyle: {
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: insets.bottom > 0 ? 0 : 8,
            },
            tabBarBackground: () => (
                <View style={{
                    backgroundColor: mainColor,
                    width: '100%',
                    height: '100%',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    overflow: 'hidden',

                }} />
            ),
            tabBarAllowFontScaling: true,
            tabBarLabelStyle: {
                margin: 0,
                // paddingBottom: Platform.OS === 'web' || Platform.OS === 'android' ? 8 : 4,
                // fontSize: 20
            },

            tabBarIconStyle: {
                height: '100%'

            },
            tabBarIcon: ({ focused, color, size }) => {
                let iconName;
                switch (route.name) {
                    case 'Home':
                        iconName = HomeSVG;
                        break;
                    case 'Search':
                        iconName = SearchSVG;
                        break;
                    case 'Orders':
                        iconName = Recent;
                        break;
                    case 'Profile':
                        iconName = Profile;
                        break;
                    default: break;
                }
                // You can return any component that you like here!
                return <Image width={23} height={23} svg uri={iconName} fill={focused ? redColor : '#121212'} />;
            },
            tabBarActiveTintColor: redColor,
            tabBarInactiveTintColor: '#121212',
        })}>
        <Tab.Screen
            name="Home"
            component={Home}
            options={{
                title: strings.home,
                header: () => null,
            }} />
        <Tab.Screen name="Orders" component={PreviousOrders} options={{
            title: strings.orders,
            header: () => <CustomHeader title={strings.orders} basic hideBack />,
        }} />
        <Tab.Screen
            name="Search"
            component={Search}
            options={{
                title: strings.search,
                header: () => null,
            }}
        />
        <Tab.Screen name="Profile" component={ProfilePage} options={{
            title: strings.profile,

            header: () => <CustomHeader basic title={strings.profile} hideBack />,
        }} />
    </Tab.Navigator>)
}
function Routes() {
    const dispatch = useDispatch()
    const [branch, setBranch] = useState(false);

    const { location } = useSelector(state => state.order);
    const { allLocations } = useSelector(state => state.settings)
    const { strings } = useSelector((state) => state.locale);
    const user = useSelector(state => state.user)


    useEffect(() => {
        setBranch(allLocations?.[location?.locationId]?.branch || false);
    }, [location, allLocations]);

    useEffect(() => {
        dispatch(SetAlertMessage(false));
    }, []);

    const openStore = () => {
        if (Platform.OS === 'android') {
            const GOOGLE_PACKAGE_NAME = 'com.tayba';
            Linking.openURL(`market://details?id=${GOOGLE_PACKAGE_NAME}`);
        } else {
            const link = `itms-apps://itunes.apple.com/us/app/id${1584107582}?mt=8`
            Linking.canOpenURL(link).then(supported => {
                supported && Linking.openURL(link);
            }, (err) => console.log(err));
        }
        _updateApp()
    };

    const _updateApp = () => {
        dispatch(SetAlertMessage({
            title: "Update Required",
            cancelText: "Update",
            msg: strings?.["New Version"],
            alert: true,
            onCancel: () => {
                openStore()
            },

        }))
    };
    useEffect(() => {
        firestore().collection('forceUpdate').doc(Platform.OS).onSnapshot((record) => {
            if (Platform.OS === 'android') {
                if (DeviceInfo?.getBuildNumber() < record?.data()?.buildNumber) {
                    _updateApp()
                }
            } else if (Platform.OS === 'ios') {
                if (DeviceInfo?.getVersion() === record?.data()?.version) {
                    if (DeviceInfo?.getBuildNumber() < record?.data()?.buildNumber) {
                        _updateApp()
                    }
                }
                try {
                    if (CompareVersions(record?.data()?.version, DeviceInfo?.getVersion())) {
                        _updateApp()
                    }
                } catch (error) {
                    console.log("errorerror", error);
                }
            }
        })
    }, []);
    useEffect(() => {
        let unsub;
        [{ label: 'prds', action: GetPrds }].map(item => {
            let ref = firestore().collection(item.label).where('active', '==', true)
            if (branch) {
                ref = ref.where('branches', 'array-contains', branch)
            }
            unsub = ref.onSnapshot(res => {
                let prds = res.docs.map(rec => {
                    return { ...rec.data(), id: rec.id }
                })
                dispatch(item.action(prds || []))
            })
        })
        return () => {
            if (unsub) {
                unsub()
            }
        }
    }, [branch])

    useEffect(() => {
        (async () => {
            [{ label: 'prds_category', action: GetCategories },
            { label: 'banners', action: GetBanner },
            { label: 'brands', action: GetBrands }].map(item => {
                let ref = firestore().collection(item.label).where('active', '==', true)
                ref.onSnapshot(res => {
                    let prds = res.docs.map(rec => {
                        return { ...rec.data(), id: rec.id }
                    })
                    dispatch(item.action(prds || []))
                })
            })

            firestore().collection('offers').where('global', '==', true).where('active', '==', true).limit(1).onSnapshot((offers) => {
                if (offers.empty) {
                    return dispatch(GetOffers([]))
                }
                offers.docs.map(rec => {
                    let { endDate } = rec.data()
                    if (!endDate || endDate > Date.now()) {
                        dispatch(GetOffers(rec.data()))
                    } else {
                        dispatch(GetOffers([]))
                    }
                })
            })

            firestore().collection('pickup_areas').where('active', '==', true).onSnapshot(snap => {
                let pickupLocations = snap.docs.reduce((val, rec) => {
                    val[rec.id] = rec.data()
                    return val
                }, {})
                dispatch(SetPickupAreas(pickupLocations))

            })
            firestore().collection('delivering_areas').where('active', '==', true).onSnapshot((snap => {
                let supportedAreas = []
                let allLocations = {}
                snap.docs.map(rec => {
                    let data = rec.data()
                    let { areas, active } = data
                    if (active) {
                        // if (true) {
                        Object.keys(areas || {}).map(areaKey => {
                            let areaObject = areas?.[areaKey]
                            let { list } = areaObject || {}
                            let optimizedList = list?.map(location => {
                                return ({ ...location, id: rec.id, key: rec.id })
                            })
                            supportedAreas = supportedAreas?.concat(optimizedList || [])
                            return false
                        })
                    }
                    allLocations[rec.id] = data

                    return false
                })
                dispatch(CheckStoreClosed());
                dispatch(SetServingAreas({ supportedAreas, allLocations }))
            }))
            firestore().collection('servingLocations').onSnapshot(snap => {
                let places = snap?.docs?.map(rec => {
                    return { ...rec.data(), id: rec.id }
                })
                dispatch(SetAppPlaces(places))
            })


            auth().onAuthStateChanged(async user => {

                if (user?.uid) {
                    let token = '';

                    try {
                        token = await requestUserPermission()
                    } catch (error) {
                        console.log("couldnt get token");
                    }
                    let update = true

                    firestore().collection('users').doc(user.uid).onSnapshot(async userSnap => {
                        if (userSnap?.exists) {
                            dispatch(OptimizeLocationToPolygons({ ...userSnap?.data(), uid: user.uid }));
                            dispatch(GetUser({ ...userSnap?.data(), uid: user.uid }))
                            if (update && (token !== userSnap.data()?.token)) {
                                update = false
                                try {
                                    firestore().collection('users').doc(user.uid).update({ token: token })
                                } catch (error) {
                                    console.log("couldnt", error);

                                }
                            }
                        } else {

                            let post = {
                                uid: user.uid,
                            }
                            if (user?.phoneNumber) {
                                post.phoneNumber = user?.phoneNumber
                            }
                            if (user?.email) {
                                post.email = user?.email
                            }
                            if (token !== userSnap?.data()?.token) {
                                post.token = token
                            }
                            firestore().collection('users').doc(user.uid).set(post, { merge: true })
                        }

                    })
                    firestore().collection('orders').where('customer.uid', '==', user?.uid).onSnapshot(orderSnaps => {
                        let orders = orderSnaps?.docs?.map(r => { return { ...r.data(), id: r.id } })
                        dispatch(GetActiveOrders(orders || []))
                    })

                } else {
                    dispatch(FlushUser())
                    dispatch(FlushOrder())
                }
            })
            if (Platform.OS === 'web') {
                let language = await AsyncStorage.getItem('language')
                dispatch(localeSetLanguage(language || 'en'))
            }

        })()

    }, [])

    return (
        <NavigationContainer theme={MyTheme} >
            <HomeStackScreen user={user} />
        </NavigationContainer>

    );
}


export default Routes;