import React from 'react';
import { Modal, View, TouchableOpacity, Dimensions } from 'react-native';
import { useSelector } from 'react-redux';
// import { TouchableOpacity } from 'react-native-gesture-handler';
import { redColor, secColor } from '../../constants/color';
import { Text } from '../shared/custom'

const Alert = ({ title = 'title', msg = '', cancelText = 'dismiss', confirmText = '', onCancel, onConfirm, alert }) => {
    const { locale, strings } = useSelector((state) => state.locale);
    return (
        <Modal
            // animationType='slide'
            presentationStyle='overFullScreen'
            transparent={true}
            visible={true}
        >

            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)', alignItems: 'center', width: Dimensions.get('window').width > 900 ? '30%' : '100%', height: '100%' }}>
                <View style={{ backgroundColor: '#fff', width: '80%', borderRadius: 16 }}>
                    <View style={{ justifyContent: 'center', alignItems: 'center', paddingVertical: 35, paddingHorizontal: 16, width: '100%' }}>
                        <Text style={{ color: '#000', fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', fontSize: 18 }}> {title}</Text>
                        <Text style={{ lineHeight: 22, textAlign: 'center', color: '#000', fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular', fontSize: 14, paddingTop: 8, width: '100%' }}>{msg}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', backgroundColor: secColor, borderBottomStartRadius: 16, borderBottomEndRadius: 16 }}>

                        <View style={{ backgroundColor: '#F5F5F5', width: alert ? '100%' : '50%', borderBottomStartRadius: 16, borderBottomEndRadius: alert ? 16 : 0 }}>
                            <TouchableOpacity style={{ paddingVertical: 16 }} onPress={onCancel}>
                                <Text style={{ fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', color: '#000000', textAlign: 'center' }}>{strings[cancelText]}</Text>
                            </TouchableOpacity>
                        </View>
                        {
                            !alert && <View style={{ backgroundColor: redColor, width: '50%', borderBottomEndRadius: 16 }}>
                                <TouchableOpacity style={{ paddingVertical: 16 }} onPress={onConfirm} >
                                    <Text style={{ color: '#fff', textAlign: 'center', fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{confirmText}</Text>
                                </TouchableOpacity>
                            </View>
                        }

                    </View>
                </View>
            </View>

        </Modal>
    );
};

export default Alert;