import React, { useState } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import { greenColor, mainColor, redColor } from '../../constants/color';
import CustomImage from './customImg'
import { Like, Add, minus } from '../../constants/imgs'
import { Text } from './custom'
import { checkDiscount } from '../../helpers/helpers';
import { UpdateOrderItems } from '../../reducers/order'
import { useNavigation } from '@react-navigation/core';
import { connect, useSelector } from 'react-redux';
import { firestore } from '../../constants/firebase/config'
import Alert from './Alert';

const Label = (prop) => {
    const { locale, strings } = useSelector((state) => state.locale);

    return (
        <View style={{ paddingVertical: 4, paddingHorizontal: 6, marginRight: 4, borderRadius: 4, backgroundColor: prop.color + 12, marginTop: prop.top || 0 }}>
            <Text style={{ fontSize: 10, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold', color: prop.color }}>{prop.title}</Text>
        </View>
    )
}
const Favourit = ({ user, id, alert }) => {
    let favourit = user?.favourit || []

    return (
        <TouchableOpacity
            onPress={() => {
                let uid = user?.uid
                if (uid) {
                    let favourit = user?.favourit || []
                    firestore().collection('users').doc(uid).update({
                        favourit: favourit.includes(id) ? firestore.FieldValue.arrayRemove(id) : firestore.FieldValue.arrayUnion(id)
                    })
                } else {

                    alert()
                }
            }}
            style={{ position: 'absolute', zIndex: 99, right: 0, padding: 6 }}>
            <CustomImage width={20} height={20} svg={true} uri={Like} fill={favourit?.includes(id) ? redColor : 'none'} />
        </TouchableOpacity>
    )
}
const ProductCard = ({ data, offer, orderItems, UpdateOrderItems, user, basic, prds, brands }) => {
    const navigation = useNavigation()
    let image = data?.thumb?.length ? (data?.thumb?.[0]?.includes('https://cdn.taybagourmet.com') ? data?.thumb?.[0] : `https://cdn.taybagourmet.com/prds/${data.id}/400`) : 'https://firebasestorage.googleapis.com/v0/b/tayba-2b919.appspot.com/o/Vi.png?alt=media&token=2a582040-3746-4821-997a-996d7cd23a45'
    let orderd = !basic && orderItems[data?.key]
    const [alert, setAlert] = useState(false)
    let discounted = checkDiscount(offer, data)
    const { locale, strings } = useSelector((state) => state.locale);
    const getBrandName = (key) => {
        let brand = brands?.find(r => r.id === key)
        if (brand) {
            if (locale === 'ae') {
                return brand.name_ar
            }
            return brand.name

        }
        return ''
    }
    return (
        <TouchableOpacity
            onPress={() => !alert && navigation.navigate('Product', { id: data.key || data.id, data: data })}
            style={{ borderRadius: 8, backgroundColor: mainColor, marginVertical: 8 }}>

            <Favourit user={user} id={data?.id} alert={() => setAlert(true)} />
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {
                    orderd && <View style={{ width: 3, height: '90%', marginHorizontal: 3, backgroundColor: redColor, borderRadius: 8 }} />

                }
                {
                    alert && <Alert title='Alert' msg={'Login to use this feature'} onConfirm={() => { setAlert(false); navigation.navigate('Login') }} confirmText='Login' onCancel={() => setAlert(false)} />
                }
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingLeft: orderd ? 8 : 16,
                    width: '100%'
                    // paddingHorizontal: orderd ? 8 : 16
                }}>
                    <View style={{ paddingBottom: 8, width: '70%', alignItems: 'flex-start' }}>
                        <Text style={{ fontSize: 10, paddingTop: 8, textTransform: 'capitalize', fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular' }}>{getBrandName(data?.brand)}</Text>
                        <Text ellipsizeMode='tail' numberOfLines={1} style={{ fontSize: 14, paddingTop: 4, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>
                            {
                                (orderd || data?.count) && <Text style={{ color: redColor }}>{`${data?.count || orderd.count}X `}</Text>
                            }
                            {locale === 'en' ? data?.name : data?.name_ar || data?.name}
                        </Text>
                        <Text style={{ fontSize: 12, paddingTop: 2, fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular' }}>{data?.detail}
                            <Text style={{ fontSize: 12, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{data?.unit?.value ? (`  ${data?.unit?.value} ${data?.unit?.label}`) : ''}</Text>

                        </Text>
                        <View style={{ flexDirection: 'row', paddingTop: 6 }}>
                            {
                                data?.newArrival && <Label title={strings.newArrival} color={redColor} />
                            }

                            {
                                discounted && <Label title={`${discounted.percent}% ${strings.off}`} color={greenColor} />
                            }
                        </View>


                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 16, }}>
                            {data?.price !== '0' ? <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                <Text style={{ fontSize: 14, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{discounted ? (data?.price - discounted?.price).toFixed(2) : data?.price} </Text>
                                <Text style={{ fontSize: 10, fontFamily: locale === 'en' ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{strings.AED}</Text>
                                {
                                    discounted && <Text style={{ fontSize: 9, paddingHorizontal: 4, fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular', textDecorationLine: 'line-through' }}>{`${strings.AED} ${data?.price}`}</Text>
                                }
                            </View> : <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={{ fontSize: 14, fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Medium', }}>{strings.priceOnSelection}</Text>
                            </View>}
                            <View style={{ display: 'flex', flexDirection: 'row', paddingHorizontal: 16 }}>
                                {orderd && <TouchableOpacity style={{ paddingHorizontal: 8 }} onPress={() => {
                                    let items = JSON.parse(JSON.stringify(orderItems))
                                    if (orderd?.count > 1) {
                                        orderd.count -= 1
                                        items[orderd.key] = orderd
                                        UpdateOrderItems(items)
                                    } else {
                                        delete items[orderd.key]
                                        UpdateOrderItems(items)
                                    }
                                }}>
                                    <CustomImage width={20} height={20} fill={redColor} svg uri={minus} />
                                </TouchableOpacity>}
                                <TouchableOpacity onPress={() => {
                                    if (orderd && orderd?.count < 10) {
                                        let items = JSON.parse(JSON.stringify(orderItems))
                                        orderd.count += 1
                                        items[orderd.key] = orderd
                                        UpdateOrderItems(items)
                                    } else {
                                        navigation.navigate('Product', { id: data.id, data })
                                    }
                                }}>
                                    <CustomImage width={20} height={20} fill={redColor} svg uri={Add} />
                                </TouchableOpacity>
                            </View>

                        </View>

                    </View>
                    <Image
                        style={{
                            width: '30%',
                            borderRadius: 8,
                            alignSelf: 'auto'
                            // marginVertical: 14,
                        }}
                        resizeMode="cover"
                        source={{ uri: image, cache: 'force-cache' }}
                    />
                </View>

            </View>

        </TouchableOpacity >
    );
};
const mapStateToProps = ({ order, offer, user, helpers }) => {
    return {
        user,
        offer,
        orderItems: order?.items || {},
        prds: helpers?.prds || [],
        brands: helpers?.brands || []
    }
}
export default connect(mapStateToProps, { UpdateOrderItems })(ProductCard);
